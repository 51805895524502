import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { useNotificationContext } from "../../contexts/Notification-Context";
import { useAuthContext } from "../../contexts/Auth-Context";
import { IRequestProps } from "./models";

export const useRequest = <T, X>({
  queryKey,
  requestBody,
  permission = true,
  request,
}: IRequestProps<T, X>) => {
  const notificationContext = useNotificationContext();
  const authContext = useAuthContext();
  const { setMessage } = notificationContext;
  const { onLogoutUser } = authContext;
  const { data, isLoading, isSuccess, refetch } = useQuery<
    AxiosResponse<X, any> | null,
    any
  >({
    queryKey: queryKey,
    queryFn: request ? () => request(requestBody) : undefined,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: permission,
    onError: (err) => {
      if (err) {
        if (err?.response) {
          if (err?.response?.status === 401) {
            onLogoutUser();
          }
          if (err.response.data.message) {
            if (Array.isArray(err.response.data.message)) {
              setMessage({
                message: err.response.data.message.join(" | "),
                type: "error",
              });
            } else {
              setMessage({
                message: err.response.data.message,
                type: "error",
              });
            }

            return {
              success: false,
              data: null,
            };
          }
          if (err.response.data.message) {
            setMessage({
              message:
                err.response.data.message === "User not found"
                  ? "Usuário não encontrado"
                  : err.response.data.message,
              type: "error",
              notificationKey: new Date().getMilliseconds(),
            });
          }
          if (err.response?.data.errors) {
            let errorMessage = "";

            Object.keys(err.response?.data.errors).forEach((key) => {
              errorMessage += err.response?.data.errors[key].join(" | ") + " ";
            });

            setMessage({
              message: errorMessage,
              type: "error",
              notificationKey: new Date().getMilliseconds(),
            });
          }
          if (err.response?.data.detail) {
            setMessage({
              message: err.response?.data.detail || err.message,
              type: "error",
              notificationKey: new Date().getMilliseconds(),
            });
          }
          setMessage({
            message: err.message,
            type: "error",
            notificationKey: new Date().getMilliseconds(),
          });
        } else {
          setMessage({
            message: "message" in err ? err.message : "",
            type: "error",
            notificationKey: new Date().getMilliseconds(),
          });
        }
      }
    },
  });

  return {
    data: data ? data.data : null,
    isLoading,
    isSuccess,
    permission,
    refetch,
  };
};
