import { Box, Grid } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { IActionFormProps } from "./models";
import { Button } from "components/UI/Button";
import { DateInput } from "components/UI/Form/DateInput";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/UI/Form";
import { FormInput } from "components/UI/Form/FormTextInput";
import { useRequest } from "hooks/useRequest/useRequets";
import { createOrUpdateAction, getActionTypes } from "services/actions.service";
import { AutoComplete } from "components/UI/Form/Autocomplete";
import { TActionForm, actionSchema } from "./actionSchema";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { useNotificationContext } from "contexts/Notification-Context";
import { useQueryClient } from "react-query";

export const ActionForm = memo(
  ({ id, squa, currentAction, onChangeRender }: IActionFormProps) => {
    const { setMessage } = useNotificationContext();

    const queryClient = useQueryClient();

    const { data: actionTypesList } = useRequest({
      queryKey: ["quality-actions"],
      requestBody: "PCP_OP_QUA_TP",
      request: getActionTypes,
    });

    const methods = useForm<TActionForm>({
      resolver: zodResolver(actionSchema),
    });

    const { handleSubmit, setValue } = methods;

    const { sendRequest, loading } = useFnRequest(createOrUpdateAction);

    const cancelHandler = useCallback(() => {
      onChangeRender("list");
    }, [onChangeRender]);

    const onSubmit = useCallback(
      async (values: TActionForm) => {
        const { data, success } = await sendRequest({
          ...values,
          sequenceQuality: squa,
          sequenceAction: currentAction ? currentAction.s_aca : undefined,
          sequence: currentAction ? 1 : 0,
          clientId: id,
          type: Number(values.type.id),
          date: values.date.toISOString(),
        });
        if (data && success) {
          cancelHandler();
          setMessage({
            message: currentAction
              ? "Ação alterada com sucesso"
              : "Ação criada com sucesso",
            type: "success",
          });
          queryClient.invalidateQueries(["actions-list"]);
        }
      },
      [
        sendRequest,
        cancelHandler,
        setMessage,
        queryClient,
        id,
        squa,
        currentAction,
      ]
    );

    const setTypeValue = useCallback(() => {
      const fixItAction = actionTypesList
        ? actionTypesList.find((type) => type.ID_TP === 2)
        : null;
      if (fixItAction) {
        setValue("type", {
          id: fixItAction.ID_TP.toString(),
          name: fixItAction.DS_TP,
        });
      }
    }, [setValue, actionTypesList]);

    useEffect(() => {
      setTypeValue();
    }, [setTypeValue]);

    useEffect(() => {
      if (currentAction) {
        console.log(currentAction.date);
        setValue("date", currentAction.date);
        setValue("responsible", currentAction.responsible);
        setValue("description", currentAction.description);
      }
    }, [currentAction, setValue]);

    return (
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <DateInput
                name="date"
                label="Data"
                initialDate={new Date()}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AutoComplete
                name="type"
                label="Tipo"
                size="small"
                disable
                getOptionLabel={(option) => option.name}
                options={
                  actionTypesList
                    ? actionTypesList?.map((type) => ({
                        id: type.ID_TP.toString(),
                        name: type.DS_TP,
                      }))
                    : []
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInput
                name="responsible"
                label="Responsável"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInput
                name="description"
                label="Defeito"
                size="small"
                multiline
                minRows={5}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "end",
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                disabled={loading}
                onClick={cancelHandler}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Form>
    );
  }
);
