import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  IQualityActionsContextProps,
  IQualityActionsContextProviderProps,
} from "./models";
import { IGetQualityAction } from "models/quality";
import { getActionsById } from "services/actions.service";
import { useRequest } from "hooks/useRequest/useRequets";

export const QualityActionsContext = createContext<IQualityActionsContextProps>(
  {
    actions: [],
  }
);

export const QualityActionsProvider: React.FC<
  IQualityActionsContextProviderProps
> = ({ id, squa, children }) => {
  const [actions, setActions] = useState<IGetQualityAction[]>([]);

  const { data } = useRequest({
    queryKey: ["actions-list"],
    requestBody: { id, squa },
    request: getActionsById,
  });

  const fetchActions = useCallback(() => {
    if (data) {
      setActions(data);
    }
  }, [data]);

  useEffect(() => {
    fetchActions();
  }, [fetchActions]);

  return (
    <QualityActionsContext.Provider
      value={{
        actions,
      }}
    >
      {children}
    </QualityActionsContext.Provider>
  );
};

export const useQualityActions = () => {
  const context = useContext(QualityActionsContext);

  return context;
};
