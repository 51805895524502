import { Box } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/UI/Form";
import { IAddProductModalProps } from "./models";
import { TAddProductForm, addProductSchema } from "./addProductSchema";
import { ProductFields } from "./ProductFields/ProductFields";
import { CostFields } from "./CostFields/CostFields";
import { ProductContextProvider } from "contexts/Product-Context/product-context";
import FinancialData from "./FinancialData/FinancialData";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { addProduct, getProduct, updateProduct } from "services/dyeing.service";
import { useNotificationContext } from "contexts/Notification-Context";
import { useUser } from "contexts/User-Context";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

export const AddProductModal = memo(
  ({ idFichaTecnica, onClose }: IAddProductModalProps) => {
    const {
      company,
      collection,
      clientId,
      clientCode,
      tabelaCustoInsumo,
      tabelaCustoOperacao,
    } = useParams();

    const { setMessage } = useNotificationContext();

    const queryClient = useQueryClient();

    const { user } = useUser();

    const { id } = user;

    const methods = useForm<TAddProductForm>({
      resolver: zodResolver(addProductSchema),
    });

    const { sendRequest, loading } = useFnRequest(addProduct);

    const { sendRequest: update } = useFnRequest(updateProduct);

    const { sendRequest: get } = useFnRequest(getProduct);

    const { handleSubmit, reset } = methods;

    const fetchProduct = useCallback(async () => {
      if (!idFichaTecnica) return;
      const { data, success } = await get(idFichaTecnica);
      if (data && success) {
        reset({
          cost: {
            ...data.cost,
            profit: {
              ID: data.cost.profit.id,
              NAME: data.cost.profit.name,
              INDICE: data.cost.profit.INDICE,
            },
            csll: data.cost.csll ?? 0,
            ir: data.cost.ir ?? 0,
            precoSugerido: String(data.product.material.cost),
          },
          product: {
            ...data.product,
            composicao: {
              ...data.product.composicao,
              code: Number(data.product.composicao.code),
            },
            material: {
              ...data.product.material,
              code: data.product.material.code,
              idFichaTecnica: data.product.material.idFicha,
              tipoFichaTecnica: data.product.material.tipoFicha,
            },
          },
        });
      }
    }, [get, reset, idFichaTecnica]);

    useEffect(() => {
      fetchProduct();
    }, [fetchProduct]);

    const onSubmit = useCallback(
      async (form: TAddProductForm) => {
        const formData = {
          cost: {
            ...form.cost,
            markupTot: form.cost.markup.MKP_TOT,
            markup: form.cost.markup.ID_TAB,
            paymentId: form.cost.payment.ID_PGTO,
            paymentTot: form.cost.payment.JUR_TOT,
            profit: form.cost.profit.ID,
          },
          list: {
            company: Number(company),
            collection: Number(collection),
            clientCode: Number(clientCode),
            tabelaCustoInsumo: Number(tabelaCustoInsumo),
            tabelaCustoOperacao: Number(tabelaCustoOperacao),
            clientId: Number(clientId),
          },
          product: {
            ...form.product,
            roteiro: form.product.roteiro.id,
            cor: form.product.cor.id,
            composicao: form.product.composicao.id,
            material: {
              ...form.product.material,
              code: Number(form.product.material.code),
              idFicha: form.product.material.idFichaTecnica,
              tipoFicha: form.product.material.tipoFichaTecnica,
            },
          },
          userID: Number(id),
        };

        if (idFichaTecnica) {
          const { data, success } = await update({
            idFichaTecnica,
            params: formData,
          });
          if (data && success) {
            setMessage({
              message: "Produto atualizado com sucesso",
              type: "success",
            });
            queryClient.invalidateQueries({ queryKey: ["datagrid-request"] });
            onClose();
          }
          return;
        }

        const { data, success } = await sendRequest(formData);
        if (data && success) {
          setMessage({
            message: "Produto adicionado com sucesso",
            type: "success",
          });
          queryClient.invalidateQueries({ queryKey: ["datagrid-request"] });
          onClose();
        }
      },
      [
        onClose,
        setMessage,
        sendRequest,
        update,
        idFichaTecnica,
        id,
        queryClient,
        company,
        collection,
        clientId,
        clientCode,
        tabelaCustoInsumo,
        tabelaCustoOperacao,
      ]
    );

    return (
      <ProductContextProvider intialStep={0}>
        <Form {...methods}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}
            sx={{
              pt: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <ProductFields onClose={onClose} idFichaTecnica={idFichaTecnica} />
            <CostFields onClose={onClose} idFichaTecnica={idFichaTecnica} />
            <FinancialData
              onClose={onClose}
              loading={loading}
              idFichaTecnica={idFichaTecnica}
            />
          </Box>
        </Form>
      </ProductContextProvider>
    );
  }
);
