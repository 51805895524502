import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BackButton } from "../UI/BackButton";
import { IPagesHeaderProps } from "./models";

const PagesHeader = ({ title }: IPagesHeaderProps) => {
  const [pos, setPos] = useState<"moved" | "top">("top");

  useEffect(() => {
    const main = document.getElementById("main");
    if (main) {
      main.addEventListener("scroll", (e) => {
        let scrolled = main.scrollTop;
        if (scrolled && scrolled >= 5) {
          setPos("moved");
        } else {
          setPos("top");
        }
      });
    }
  }, []);

  return (
    <Stack
      alignItems={"start"}
      sx={(theme) => ({
        paddingY: pos === "moved" ? 2 : 0,
        width: "100%",
        position: "sticky",
        top: pos === "moved" ? 15 : 0,
        backgroundColor: theme.palette.background.default,
        zIndex: 2,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor:
          pos === "moved" ? theme.palette.divider : "transparent",
        transition: "border-color 0.2s linear",
      })}
    >
      <Stack
        width={"100%"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <BackButton />
      </Stack>
      <Stack sx={{ width: "80%" }} alignSelf={"center"}>
        <Typography variant="h5">{title}</Typography>
      </Stack>
    </Stack>
  );
};

export default PagesHeader;
