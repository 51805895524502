import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { IFormCheckBoxProps } from "./models";
import { useLocation } from "react-router-dom";

export const FormCheckBox: React.FC<IFormCheckBoxProps> = ({
  name,
  label,
  ...otherProps
}) => {
  const { control } = useFormContext();
  const { pathname } = useLocation();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...otherProps}
              {...field}
              checked={field.value}
              disableRipple
              onChange={(e, v) => {
                if (otherProps.onChange) {
                  otherProps.onChange(e, v);
                }
                if (!pathname.includes("visualizar")) {
                  field.onChange(e.target.checked);
                }
              }}
              color={pathname.includes("visualizar") ? "default" : "primary"}
            />
          }
          label={label}
        />
      )}
    />
  );
};
