import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import MenuList from "./MenuList";
import Logo from "../Logo";
import { useNavigate } from "react-router-dom";
import { useMedias } from "../../../contexts/Medias-Context/medias-context";

const SideMenu = ({
  open,
  onCloseHandler,
}: {
  open: boolean;
  onCloseHandler: () => void;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();

  const { mediaFiles, slogan } = useMedias();

  const logoLightMode =
    mediaFiles && mediaFiles.length > 0
      ? mediaFiles.find((media) => media.mediaType.code === "1")
      : null;

  const logoDarkMode =
    mediaFiles && mediaFiles.length > 0
      ? mediaFiles.find((media) => media.mediaType.code === "2")
      : null;

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: 250 },
      }}
    >
      {!matches ? (
        <Box
          sx={(theme) => ({
            height: 1,
            position: "fixed",
            zIndex: 11,
            width: 250,
            boxShadow: `0px 0px 5px 0px ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <Box position={"absolute"} width={"100%"} top={-10}>
            {theme.palette.mode === "light" && (
              <Tooltip
                title={slogan}
                disableHoverListener={slogan ? false : true}
              >
                <div>
                  <Logo
                    paddingTop={10}
                    logoWidth={"55%"}
                    src={
                      theme.palette.mode === "light" && logoLightMode
                        ? logoLightMode.cdnDomain + "/" + logoLightMode.filePath
                        : "/logo.png"
                    }
                    alt={slogan || "Logo Pygg"}
                  />
                </div>
              </Tooltip>
            )}
            {theme.palette.mode === "dark" && (
              <Tooltip
                title={slogan}
                disableHoverListener={slogan ? false : true}
              >
                <div>
                  <Logo
                    paddingTop={10}
                    logoWidth={"55%"}
                    src={
                      logoDarkMode
                        ? logoDarkMode.cdnDomain + "/" + logoDarkMode.filePath
                        : "/logo_dark.png"
                    }
                    alt={slogan || "Logo Pygg"}
                  />
                </div>
              </Tooltip>
            )}
            <MenuList />
          </Box>
        </Box>
      ) : (
        <Drawer
          open={open}
          onClose={onCloseHandler}
          PaperProps={{
            sx: {
              width: 300,
            },
          }}
        >
          <Box
            paddingTop={5}
            paddingBottom={5}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={() => navigate("")}
          >
            {theme.palette.mode === "light" && (
              <Tooltip
                title={slogan}
                disableHoverListener={slogan ? false : true}
              >
                <div>
                  <Logo
                    paddingTop={5}
                    logoWidth={"55%"}
                    src={
                      theme.palette.mode === "light" && logoLightMode
                        ? logoLightMode.cdnDomain + "/" + logoLightMode.filePath
                        : "/logo.png"
                    }
                    alt={slogan || "Logo Pygg"}
                  />
                </div>
              </Tooltip>
            )}
            {theme.palette.mode === "dark" && (
              <Tooltip
                title={slogan}
                disableHoverListener={slogan ? false : true}
              >
                <div>
                  <Logo
                    paddingTop={5}
                    logoWidth={"55%"}
                    src={
                      logoDarkMode
                        ? logoDarkMode.cdnDomain + "/" + logoDarkMode.filePath
                        : "/logo_dark.png"
                    }
                    alt={slogan || "Logo Pygg"}
                  />
                </div>
              </Tooltip>
            )}
          </Box>
          <MenuList onClose={onCloseHandler} />
        </Drawer>
      )}
    </Box>
  );
};

export default SideMenu;
