import { IGetQualityAction, IGetQualityByIdParams } from "models/quality";
import { GlobalAxios } from "./../axios/axios-config";
import {IDeleteActionParams, INewAction} from "models/actions";

const ACTIONS_ROUTE = "/api/action";

interface ISelectActionType {
  ID_TP: number;
  DS_TP: string;
}

export const createOrUpdateAction = (item: INewAction) => {
  return GlobalAxios.post(ACTIONS_ROUTE, item);
};

export const deleteAction = (item: IDeleteActionParams) => {
  return GlobalAxios.delete(ACTIONS_ROUTE, {
    params: item
  });
};

export const getActionTypes = (table: string) => {
  return GlobalAxios.get<ISelectActionType[]>(ACTIONS_ROUTE + "/filter", {
    params: { table },
  });
};

export const getActionsById = (params: IGetQualityByIdParams) => {
  const { id, squa } = params;
  return GlobalAxios.get<IGetQualityAction[]>(ACTIONS_ROUTE + `/${id}/${squa}`);
};
