import { z } from "zod";

export const qualitySchema = z.object({
  date: z.date(),
  resolv: z.boolean(),
  sequence: z.string().optional(),
  clientId: z.string(),
  clientName: z.string(),
  code: z.string(),
  article: z.string(),
  description: z.string(),
  responsible: z.string(),
});

export type TQualityForm = z.infer<typeof qualitySchema>;
