import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import AccountPopover from "./AccountPopover";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { ThemeSwitch } from "../ThemeSwitch";
import { IEstablishment } from "../Establishments";
import Logo from "../Logo";
import HelpButton from "../HelpButton/HelpButton";
import { useMedias } from "../../../contexts/Medias-Context/medias-context";

const Header = ({
  onOpenHandler,
  establishments,
}: {
  onOpenHandler: () => void;
  establishments: IEstablishment[];
}) => {
  const { mediaFiles, slogan } = useMedias();

  const logoLightMode =
    mediaFiles && mediaFiles.length > 0
      ? mediaFiles.find((media) => media.mediaType.code === "1")
      : null;
  const logoDarkMode =
    mediaFiles && mediaFiles.length > 0
      ? mediaFiles.find((media) => media.mediaType.code === "2")
      : null;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  // const handleGetEstablishment = useCallback(
  //   async (establishmentId: string) => {
  //     return await authService.tokenByToken({ establishmentId });
  //   },
  //   []
  // );

  return (
    <AppBar
      sx={(theme) => ({
        height: 50,
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: matches ? "space-between" : "end",
        zIndex: 10,
        boxShadow: "none",
        backgroundColor: theme.palette.background.default,
        position: "fixed",
      })}
    >
      {matches && (
        <Stack
          width={150}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
        >
          <IconButton onClick={onOpenHandler}>
            <MenuRoundedIcon />
          </IconButton>
          {theme.palette.mode === "light" && (
            <Tooltip
              title={slogan}
              disableHoverListener={slogan ? false : true}
            >
              <div>
                <Logo
                  logoWidth={40}
                  src={
                    theme.palette.mode === "light" && logoLightMode
                      ? logoLightMode.cdnDomain + "/" + logoLightMode.filePath
                      : "/favicon.png"
                  }
                  alt={slogan || "Logo Pygg"}
                />
              </div>
            </Tooltip>
          )}
          {theme.palette.mode === "dark" && (
            <Tooltip
              title={slogan}
              disableHoverListener={slogan ? false : true}
            >
              <div>
                <Logo
                  logoWidth={40}
                  src={
                    logoDarkMode
                      ? logoDarkMode.cdnDomain + "/" + logoDarkMode.filePath
                      : "/favicon.png"
                  }
                  alt={slogan || "Logo Pygg"}
                />
              </div>
            </Tooltip>
          )}
        </Stack>
      )}
      <Toolbar
        sx={{
          px: { lg: 5 },
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {/* <EstablishmentChange
            getEstablishmentToken={handleGetEstablishment}
            onSaveToken={(access_token, refresh_token, index, id) => {
              tokenService.saveTokens(
                access_token,
                refresh_token,
                index.toString(),
                id
              );
            }}
            establishments={establishments}
          /> */}
          <ThemeSwitch
            iconButtonProps={{
              size: "medium",
            }}
          />
          <HelpButton />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
