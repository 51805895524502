import { createContext, useCallback, useContext, useState } from "react";

interface IProductContext {
  step: number;
  onChangeStep: (step: number) => void;
}

interface IProductContextProviderProps {
  children: JSX.Element;
  intialStep?: number;
}

const productContext = createContext<IProductContext>({
  step: 0,
  onChangeStep: () => {},
});

export const ProductContextProvider: React.FC<IProductContextProviderProps> = ({
  intialStep = 0,
  children,
}) => {
  const [step, setStep] = useState(intialStep);

  const onChangeStep = useCallback((newStep: number) => {
    setStep(newStep);
  }, []);

  return (
    <productContext.Provider
      value={{
        step,
        onChangeStep,
      }}
    >
      {children}
    </productContext.Provider>
  );
};

export const useProduct = () => {
  const context = useContext(productContext);

  return context;
};
