import { useContext } from "react";

import { createContext, useCallback, useLayoutEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { IDomainContextProps, IDomainProviderProps } from "./models";
import { UsePrivileges } from "../Privileges-Context";

export const ALL_APP_DOMAIN = "platform-all";

export const DomainContext = createContext<IDomainContextProps>({
  domain: "",
  privileges: [],
});

/**
 * Domain context
 *
 * @param param the domain props
 * @returns the context
 */
export const DomainProvider = ({
  children,
  value,
  redirect = true,
  actions,
}: IDomainProviderProps) => {
  const privilegesContext = UsePrivileges();

  if (privilegesContext === null) {
    throw new Error("No PrivilegesContext provided");
  }

  const { privileges } = privilegesContext;

  const { eI } = useParams();
  const [hasPermission, setHasPermission] = useState(true);

  const checkPermission = useCallback(() => {
    const allPrivileges = privileges.flatMap((priv) => priv.privileges);
    if (!actions) {
      setHasPermission(true);
      return;
    }

    if (allPrivileges.find((d) => d === ALL_APP_DOMAIN)) {
      setHasPermission(true);
      return;
    }

    const actualPrivilege =
      value?.domain + "-" + actions.toString().toLowerCase();

    if (actualPrivilege) {
      if (allPrivileges.includes(actualPrivilege)) {
        setHasPermission(true);
        return;
      } else {
        setHasPermission(false);
      }
    }

    if (value?.domain) {
      const privilegeAll = value?.domain + "-all";

      if (allPrivileges.includes(privilegeAll)) {
        setHasPermission(true);
        return;
      }
      if (allPrivileges.includes(value.domain)) {
        setHasPermission(true);
        return;
      } else {
        setHasPermission(false);
      }
    }
  }, [privileges, value, actions]);

  useLayoutEffect(() => {
    checkPermission();
  }, [checkPermission]);

  return (
    <>
      {hasPermission && (
        <DomainContext.Provider value={{ ...value, privileges }}>
          {children}
        </DomainContext.Provider>
      )}
      {!hasPermission && redirect && <Navigate to={`/e/${eI}/no-access`} />}
    </>
  );
};

export const useDomain = () => {
  const context = useContext(DomainContext);

  return context;
};
