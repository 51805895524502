import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { WelcomeIllustration } from "../assets/illustrations";

const WelcomePage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      height={"100%"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={5}
    >
      <Typography variant="h5">Seja Bem-vindo(a)</Typography>
      <img
        width={matches ? "100%" : 300}
        alt="Em desenvolvimento"
        src={`data:image/svg+xml;base64,${WelcomeIllustration}`}
      />
    </Stack>
  );
};

export default WelcomePage;
