import { DatePicker, LocalizationProvider, ptBR } from "@mui/x-date-pickers";
import { IDatePickerUncontrolledProps } from "./models";
import { memo, useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import useDate from "hooks/useDate";
export const DatePickerUncontrolled = memo(
  ({
    label,
    fullwidth,
    autoFocus,
    open,
    minDate,
    maxDate,
    value,
    isClearable = true,
    disableFuture,
    onChange,
    onClose,
  }: IDatePickerUncontrolledProps) => {
    const { dayjs } = useDate();

    const [selectOpen, setSelectOpen] = useState(open);

    useEffect(() => {
      if (open) {
        setSelectOpen(true);
      } else {
        setSelectOpen(false);
      }
    }, [open]);

    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={dayjs.locale()}
      >
        <DatePicker
          value={value}
          open={selectOpen}
          onClose={() => {
            onClose && onClose();
            setSelectOpen(false);
          }}
          onOpen={() => setSelectOpen(true)}
          dayOfWeekFormatter={(_, date: Dayjs) =>
            date.format("dddd").charAt(0).toUpperCase()
          }
          localeText={
            ptBR.components.MuiLocalizationProvider.defaultProps.localeText
          }
          format={"DD/MM/YYYY"}
          slotProps={{
            textField: {
              label: label,
              size: "small",
              fullWidth: fullwidth,
            },
            actionBar: {
              actions: isClearable ? ["clear", "accept"] : undefined,
            },
          }}
          minDate={minDate}
          maxDate={maxDate}
          disableFuture={disableFuture}
          autoFocus={autoFocus}
          onChange={onChange}
        />
      </LocalizationProvider>
    );
  }
);
