import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Chip } from "@mui/material";
import PivotDataGrid from "components/UI/PivotDataGrid/PivotDataGrid";
import { IColumn } from "components/UI/PivotDataGrid/models";
import dayjs from "dayjs";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { useRequest } from "hooks/useRequest/useRequets";
import { IQuality } from "models/quality";
import { useCallback, useMemo, useState } from "react";
import { companiesList } from "services/companies.service";
import { deleteQuality, getAllQualities } from "services/quality.service";
import { EditQualityModal } from "./EditQualityModal/EditQualityModal";
import { NewQualityModal } from "./NewQualityModal/NewQualityModal";

const QualityPage = () => {
  const [open, setOpen] = useState(false);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const defaultEndDate = useMemo(() => new Date(), []);

  const defaultInitialDate = useMemo(() => new Date(), []);

  defaultEndDate.setHours(15);
  defaultInitialDate.setHours(15);
  defaultInitialDate.setDate(defaultEndDate.getDate() - 30);

  const { sendRequest } = useFnRequest(deleteQuality);

  const { data } = useRequest({
    queryKey: ["companies-list"],
    requestBody: null,
    request: companiesList,
  });

  const columns: IColumn[] = [
    {
      caption: "Partida",
      dataField: "clientId",
      dataType: "number",
      width: 100,
    },
    {
      caption: "Cliente",
      dataField: "clientName",
    },
    {
      caption: "Artigo",
      dataField: "description",
    },
    {
      caption: "Status",
      dataField: "resolv",
      width: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.data.resolv === 1 ? "Resolvido" : "Não resolvido"}
            color={params.data.resolv === 1 ? "success" : "error"}
          />
        );
      },
    },
  ];

  const deleteHandler = useCallback(
    async (value: IQuality) => {
      const { data, success } = await sendRequest({
        company: value.clientId,
        S_QUA: value.sequence,
      });
      if (data && success) {
        return true;
      }
      return false;
    },
    [sendRequest]
  );

  return (
    <>
      <NewQualityModal open={open} onClose={closeHandler} />
      <PivotDataGrid<IQuality>
        title="Qualidade"
        columns={columns}
        request={getAllQualities}
        onDelete={deleteHandler}
        onRowClick={(e, onClose) => (
          <EditQualityModal data={e.data} onClose={onClose!} />
        )}
        actionButton={{
          label: "Lista",
          startIcon: <AddRoundedIcon />,
          onClick: openHandler,
        }}
        confirmOptions={{
          title: "Tem certeza que deseja excluir a Qualidade?",
          description: "Essa ação irá remover permanentemente a Qualidade.",
        }}
        filters={[
          {
            key: "company",
            extraSmallSize: 12,
            smallSize: 4,
            label: "Empresa",
            type: "autocomplete",
            isClearable: false,
            onDefaultValue: (options) => options[0],
            options: data
              ? data.map((d) => ({ id: d.id.toString(), name: d.companyName }))
              : undefined,
          },
          {
            key: "startDate",
            extraSmallSize: 12,
            smallSize: 2,
            label: "Data de início",
            type: "date",
            initialDate: dayjs(defaultInitialDate),
          },
          {
            key: "endDate",
            extraSmallSize: 12,
            smallSize: 2,
            label: "Data de fim",
            type: "date",
            initialDate: dayjs(defaultEndDate),
          },
          {
            key: "hideResolved",
            extraSmallSize: 12,
            smallSize: 4,
            label: "Ocultar resolvidos",
            type: "checkbox",
            initialChecked: false,
          },
        ]}
      />
    </>
  );
};

export default QualityPage;
