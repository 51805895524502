import { GlobalAxios } from "../axios/axios-config";
import {
  IForgotPassword,
  ILoginUser,
  IResetPassword,
  ITokenByToken,
  IUsersTokenPost,
  IUsersTokenPostResponse,
} from "../models/user";
import { getDomain } from "./domain.service";
import { tokenService } from "./token.service";
import axios from "axios";

const BASE_URL = "https://" + getDomain() + process.env.REACT_APP_API;
// const BASE_URL = "http://192.168.70.248:4000";

const login = (user: ILoginUser) => {
  const grant_type = "password";

  const request: IUsersTokenPost = {
    password: user.password,
    username: user.username,
    grant_type,
  };

  return axios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/authentication",
    request
  );
};

const forgotPassword = (user: IForgotPassword) => {
  const request: IForgotPassword = {
    login: user.login,
  };

  return axios.post<IForgotPassword>(
    BASE_URL + "/api/user/forgotPassword",
    request
  );
};

const resetPassword = (user: IResetPassword) => {
  const request: IResetPassword = {
    ...user,
  };

  return axios.post<IResetPassword>(
    BASE_URL + "/api/user/resetPassword",
    request
  );
};

const refresh_token = (establishmentId?: string) => {
  const refresh_token = tokenService.getTokens().refresh_token;

  return axios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/authentication/refresh",
    null,
    {
      headers: {
        Authorization: "Bearer " + refresh_token,
      },
    }
  );
};

const tokenByToken = ({ establishmentId, token }: ITokenByToken) => {
  return GlobalAxios.post<IUsersTokenPostResponse>(
    BASE_URL + `/api/authentication/tokenByToken`,
    { establishment_id: establishmentId },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const authService = {
  login,
  refresh_token,
  tokenByToken,
  forgotPassword,
  resetPassword,
};
