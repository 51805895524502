import React, { useContext } from "react";

import { createContext } from "react";
import { IAuthContext, IAuthContextProviderProps } from "./models";
import { useNotificationContext } from "../Notification-Context";

export const AuthContext = createContext<IAuthContext>({
  onLogoutUser: () => {},
});

/**
 * An context where any axios request with status 401 call refresh token and logout user
 *
 * @param param AuthContext props
 * @returns The Auth Context
 */
export const AuthContextProvider: React.FC<IAuthContextProviderProps> = ({
  children,
  onLogoutUser,
  axios,
  refreshToken,
}) => {
  const { setMessage } = useNotificationContext();

  axios.interceptors.response.use(
    (response) => {
      return { ...response, data: response.data || true };
    },
    async function (error) {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const newToken = await refreshToken(originalRequest);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        } catch (error: any) {
          if (error) {
            if (error.response.data) {
              setMessage({
                message: error.response.data.message,
                type: "error",
              });
            }
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{
        onLogoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * An hook to utilize auth context
 *
 * @returns the auth context object
 */
export const useAuthContext = () => {
  const context = useContext(AuthContext);

  return context;
};
