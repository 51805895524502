import { Box, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TAddProductForm } from "../../addProductSchema";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { ISuggestedPriceFieldProps } from "./models";

export const SuggestedPriceField = memo(
  ({ calculatedPrice }: ISuggestedPriceFieldProps) => {
    const { control, watch } = useFormContext<TAddProductForm>();

    const material = watch("product.material");

    const currentSuggestedPrice = useWatch({
      name: "cost.precoSugerido",
      control,
    });

    return (
      <Grid
        item
        mt={4}
        xs={12}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {material && (material.cost || material.cost === 0) && (
          <Typography variant="h5" fontWeight="bold">
            {`R$ ${calculatedPrice.toFixed(2)}`}
          </Typography>
        )}
        {/* <FormInput
        name="cost.precoSugerido"
        label="Preço Sugerido"
        size="small"
      /> */}
        {currentSuggestedPrice && (
          <Box
            sx={(t) => ({
              width: 30,
              height: 30,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                material && Number(currentSuggestedPrice) >= material.cost
                  ? t.palette.success.main
                  : t.palette.error.main,
              color: "#fff",
              borderRadius: 15,
            })}
          >
            {material !== null &&
            Number(currentSuggestedPrice) >= material.cost ? (
              <CheckRoundedIcon />
            ) : (
              <CloseRoundedIcon />
            )}
          </Box>
        )}
      </Grid>
    );
  }
);
