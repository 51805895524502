import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { NotFoundIllustration } from "../assets/illustrations";

const ErrorElement = () => {
  const navigate = useNavigate();

  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        component="header"
        sx={{
          top: 20,
          left: 0,
          width: 1,
          lineHeight: 0,
          position: "fixed",
          textAlign: "center",
          p: (theme) => ({
            xs: theme.spacing(3, 3, 0),
            sm: theme.spacing(5, 5, 0),
          }),
        }}
      >
        <Typography variant="h4" textAlign="center" fontWeight="bold">
          ACCL
        </Typography>
        {/* <Logo
          src="/gera_lead_horizontal_logo.png"
          alt="Logo Gera Lead"
          logoWidth={matches ? "50%" : "15%"}
        /> */}
      </Box>

      <Container>
        <Box
          sx={{
            py: 12,
            maxWidth: 480,
            mx: "auto",
            display: "flex",
            minHeight: "100vh",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 3 }}>
            Ops! Página não encontrada!
          </Typography>

          <Box
            component="img"
            src={`data:image/svg+xml;base64,${NotFoundIllustration}`}
            sx={{
              mx: "auto",
              width: "100%",
              my: { xs: 5, sm: 10 },
            }}
          />

          <Button size="large" variant="contained" onClick={() => navigate(-1)}>
            Voltar
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default ErrorElement;
