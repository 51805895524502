import { GlobalAxios } from "../axios/axios-config";
import {
  IContentHelp,
  IGetContentHelp,
  IListContentHelp,
} from "../models/content-help";

const CONTENT_HELP_ROUTE = process.env.REACT_APP_API + "/api/helpContent";

export const createContentHelp = (item: IContentHelp) => {
  return GlobalAxios.post(CONTENT_HELP_ROUTE, item);
};

export const getContentHelpById = (id: string) => {
  return GlobalAxios.get<IGetContentHelp>(CONTENT_HELP_ROUTE + "/" + id);
};

export const updateContentHelpById = ({
  item,
  id,
}: {
  item: IContentHelp;
  id: string;
}) => {
  return GlobalAxios.put(CONTENT_HELP_ROUTE + "/" + id, item);
};

export const listContentHelp = () => {
  return GlobalAxios.get<IListContentHelp[]>(CONTENT_HELP_ROUTE + "/list");
};
