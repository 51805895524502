import {
  Autocomplete,
  Box,
  Chip,
  Paper,
  TextField,
  createFilterOptions,
} from "@mui/material";

import { HTMLAttributes, memo, useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { IFormAutoCompleteColorProps } from "./models";
import { useFetch } from "../../../../hooks/useFetch";
import { useDebounce } from "../../../../hooks/useDebounce";
import { Button } from "../../Button/Button";
import { DomainProvider } from "../../../../contexts/Domain-Context";
import { useLocation } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ISelectColor } from "models/colors";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: ISelectColor) => option.name + option.code + option.RGB,
});

export const AutoCompleteColor = memo(
  ({
    name,
    options,
    params,
    domain,
    actions,
    onValueSelected,
    addButtonText,
    onAddButton,
    request,
    getOptionLabel,
    SearchAsYouTypeParams,
    isOptionEqualToValue = (o, v) => {
      return o.id === v.id;
    },
    disable,
    filterSelectedOptions = false,
    canClear = true,
    noOptionsText = "Sem opções",
    renderOption = (props, option) => {
      return (
        <li
          {...props}
          key={option.id}
          style={{
            paddingLeft: 30,
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: 20,
              backgroundColor: option.RGB,
              position: "absolute",
              left: 0,
              boxShadow: 1,
            }}
          />
          {option.name + ` (Cod.: ${option.code})`}
        </li>
      );
    },
    ...otherProps
  }: IFormAutoCompleteColorProps) => {
    const { control } = useFormContext();
    const [realOptions, setOptions] = useState<ISelectColor[]>(options || []);
    const { loading, sendRequest } = useFetch(request);
    const [search, setSearch] = useState<string>("");
    const { pathname } = useLocation();

    const searchQuery: string = useDebounce(search, 500);

    const loadRequest = useCallback(
      async (parameters?: any) => {
        const { data, success } = await sendRequest(parameters);

        if (success) {
          setOptions(data);
        }
      },
      [sendRequest]
    );

    //Debounce get function
    useEffect(() => {
      if (SearchAsYouTypeParams) {
        if (searchQuery !== "" && searchQuery.length > 2) {
          loadRequest(SearchAsYouTypeParams(searchQuery));
        }
      }
    }, [searchQuery, SearchAsYouTypeParams, loadRequest]);

    useEffect(() => {
      if (!SearchAsYouTypeParams) {
        loadRequest(params);
      }
    }, [loadRequest, SearchAsYouTypeParams, params, searchQuery]);

    const paperOptions = (options: HTMLAttributes<HTMLElement> | any) => {
      const { containerProps, children } = options;
      return (
        <Paper {...containerProps}>
          {children}
          {searchQuery.length !== 0 && onAddButton && (
            <DomainProvider
              value={{ domain: domain ? domain : "" }}
              actions={actions}
              redirect={false}
            >
              <Button
                startIcon={<AddRoundedIcon />}
                size="small"
                fullWidth
                onMouseDown={(e) => {
                  e.preventDefault();
                  onAddButton(searchQuery);
                  setSearch("");
                }}
              >
                {addButtonText && addButtonText(searchQuery)}
              </Button>
            </DomainProvider>
          )}
        </Paper>
      );
    };

    return (
      <Controller
        render={({ fieldState: { error }, field }) => {
          const { onChange, value, ref } = field;
          return (
            <Autocomplete
              value={value}
              loading={loading}
              loadingText={"Carregando..."}
              disabled={
                disable || pathname.includes("visualizar") ? true : false
              }
              noOptionsText={noOptionsText}
              renderOption={renderOption}
              isOptionEqualToValue={isOptionEqualToValue}
              filterSelectedOptions={filterSelectedOptions}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    color="primary"
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              PaperComponent={addButtonText ? paperOptions : undefined}
              filterOptions={params ? filterOptions : (x) => x}
              options={
                options
                  ? options.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                  : realOptions
              }
              getOptionLabel={getOptionLabel}
              clearIcon={
                canClear ? <CloseRoundedIcon fontSize="small" /> : null
              }
              onChange={(event: any, newValue) => {
                onChange(newValue);
                if (newValue) {
                  if (otherProps.onChange) {
                    otherProps.onChange(event);
                  }
                  if (onValueSelected) {
                    onValueSelected(newValue);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...otherProps}
                  error={!!error}
                  onChange={(e) => {
                    onChange(null);
                    setSearch(e.target.value);
                  }}
                  inputRef={ref}
                  helperText={
                    error
                      ? error?.message
                      : SearchAsYouTypeParams
                      ? "Digite ao menos 3 caracteres para pesquisar"
                      : ""
                  }
                  fullWidth
                  sx={(t) => ({
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: pathname.includes("visualizar")
                        ? t.palette.text.primary
                        : undefined,
                    },
                  })}
                />
              )}
            />
          );
        }}
        defaultValue={null}
        name={name}
        control={control}
      />
    );
  }
);
