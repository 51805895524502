import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { IDisplayHtmlProps } from "./models";

const DisplayHtml = ({ content }: IDisplayHtmlProps) => {
  const editor = useEditor({
    editable: false,
    content: content,
    extensions: [StarterKit],
  });

  return <EditorContent editor={editor} />;
};

export default DisplayHtml;
