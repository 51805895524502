import {
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const PagesContainer = ({
  title,
  children,
  paddingRight,
  width,
  loading = false,
  backButton = false,
}: {
  width?: string | number;
  paddingRight?: string | number;
  loading?: boolean;
  title: string;
  children: any;
  backButton?: boolean;
}) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      sx={{
        width: width ? width : matches ? "100%" : "80%",
        paddingRight: paddingRight ?? undefined,
        position: "relative",
        height: "100%",
        overflow: matches ? "auto" : "hidden",
      }}
    >
      <Stack
        mt={3}
        mb={matches ? 2 : 0}
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={2}
      >
        {backButton && (
          <Tooltip title={"Voltar"}>
            <IconButton
              size="small"
              aria-label="back"
              onClick={() => navigate(-1)}
              sx={{ padding: 0 }}
            >
              <ArrowBackIosNewRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
        {loading && (
          <Skeleton
            variant="rectangular"
            width={"20%"}
            height={35}
            sx={{ borderRadius: 1 }}
          />
        )}
        {!loading && <Typography variant="h5">{title}</Typography>}
      </Stack>
      {children}
    </Stack>
  );
};

export default PagesContainer;
