import {
  Autocomplete,
  Paper,
  TextField,
  createFilterOptions,
} from "@mui/material";

import { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { IFormAutoCompleteGenericProps } from "./models";
import { useFetch } from "../../../../hooks/useFetch";
import { useDebounce } from "../../../../hooks/useDebounce";
import { Button } from "../../Button/Button";
import { DomainProvider } from "../../../../contexts/Domain-Context";
import { useLocation } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const AutoCompleteGeneric = <T extends object>({
  name,
  options,
  params,
  domain,
  actions,
  disable,
  filterSelectedOptions = false,
  canClear = true,
  noOptionsText = "Sem opções",
  onFilter,
  onValueSelected,
  addButtonText,
  onAddButton,
  request,
  getOptionLabel,
  SearchAsYouTypeParams,
  isOptionEqualToValue,
  renderOption,
  ...otherProps
}: IFormAutoCompleteGenericProps<T>) => {
  const { control } = useFormContext();
  const [realOptions, setOptions] = useState<T[]>(options || []);
  const { loading, sendRequest } = useFetch(request);
  const [search, setSearch] = useState<string>("");
  const { pathname } = useLocation();

  const searchQuery: string = useDebounce(search, 500);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: onFilter ? (option: T) => onFilter(option) : (option: T) => "",
  });

  const loadRequest = useCallback(
    async (parameters?: any) => {
      const { data, success } = await sendRequest(parameters);

      if (success) {
        setOptions(data);
      }
    },
    [sendRequest]
  );

  //Debounce get function
  useEffect(() => {
    if (SearchAsYouTypeParams) {
      if (searchQuery !== "" && searchQuery.length > 2) {
        loadRequest(SearchAsYouTypeParams(searchQuery));
      }
    }
  }, [searchQuery, SearchAsYouTypeParams, loadRequest]);

  useEffect(() => {
    if (!SearchAsYouTypeParams) {
      loadRequest(params);
    }
  }, [loadRequest, SearchAsYouTypeParams, params, searchQuery]);

  const paperOptions = (options: HTMLAttributes<HTMLElement> | any) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps}>
        {children}
        {searchQuery.length !== 0 && onAddButton && (
          <DomainProvider
            value={{ domain: domain ? domain : "" }}
            actions={actions}
            redirect={false}
          >
            <Button
              startIcon={<AddRoundedIcon />}
              size="small"
              fullWidth
              onMouseDown={(e) => {
                e.preventDefault();
                onAddButton(searchQuery);
                setSearch("");
              }}
            >
              {addButtonText && addButtonText(searchQuery)}
            </Button>
          </DomainProvider>
        )}
      </Paper>
    );
  };

  return (
    <Controller
      render={({ fieldState: { error }, field }) => {
        const { onChange, value, ref } = field;
        return (
          <Autocomplete
            value={value}
            loading={loading}
            loadingText={"Carregando..."}
            disabled={disable || pathname.includes("visualizar") ? true : false}
            noOptionsText={noOptionsText}
            renderOption={renderOption}
            isOptionEqualToValue={isOptionEqualToValue}
            filterSelectedOptions={filterSelectedOptions}
            PaperComponent={addButtonText ? paperOptions : undefined}
            filterOptions={params ? filterOptions : (x) => x}
            options={options ? options : realOptions}
            getOptionLabel={getOptionLabel}
            clearIcon={canClear ? <CloseRoundedIcon fontSize="small" /> : null}
            onChange={(event: any, newValue) => {
              onChange(newValue);
              if (newValue) {
                if (otherProps.onChange) {
                  otherProps.onChange(event);
                }
                if (onValueSelected) {
                  onValueSelected(newValue);
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...otherProps}
                error={!!error}
                onChange={(e) => {
                  onChange(null);
                  setSearch(e.target.value);
                }}
                inputRef={ref}
                helperText={
                  error
                    ? error?.message
                    : SearchAsYouTypeParams
                    ? "Digite ao menos 3 caracteres para pesquisar"
                    : ""
                }
                fullWidth
                sx={(t) => ({
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: pathname.includes("visualizar")
                      ? t.palette.text.primary
                      : undefined,
                  },
                })}
              />
            )}
          />
        );
      }}
      defaultValue={null}
      name={name}
      control={control}
    />
  );
};

export default AutoCompleteGeneric;
