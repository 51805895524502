import { GlobalAxios } from "./../axios/axios-config";
import {
  IDeleteQualityParams,
  IGetQuality,
  IGetQualityByIdParams,
  INewQuality,
  IQuality,
  IQualityParams,
} from "models/quality";

const QUALITY_ROUTE = "/api/quality";

export const createOrUpdateQuality = (item: INewQuality) => {
  return GlobalAxios.post(QUALITY_ROUTE, item);
};

export const getQualityById = (params: IGetQualityByIdParams) => {
  const { id, squa } = params;
  return GlobalAxios.get<IGetQuality[]>(QUALITY_ROUTE + `/${id}/${squa}`);
};

export const getAllQualities = (params: IQualityParams) => {
  return GlobalAxios.get<IQuality[]>(QUALITY_ROUTE, {
    params,
  });
};

export const deleteQuality = (params: IDeleteQualityParams) => {
  return GlobalAxios.delete(QUALITY_ROUTE + "/filter", { params });
};
