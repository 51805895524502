import { Grid } from "@mui/material";
import { DatePickerUncontrolled } from "components/UI/DatePickerUncontrolled/DatePickerUncontrolled";
import { memo, useCallback, useLayoutEffect } from "react";
import { IDateFilterProps } from "./models";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

export const DateFilter = memo(({ filter }: IDateFilterProps) => {
  const { key, label, extraSmallSize, smallSize, initialDate } = filter;

  const [params, setParams] = useSearchParams();

  const currentValue = params.get(key);

  const onChangeHandler = useCallback(
    (value: dayjs.Dayjs | null) => {
      if (value) {
        setParams((p) => {
          p.set(key, value.format("YYYY-MM-DD"));
          return p;
        });
      } else {
        setParams((p) => {
          p.delete(key);
          return p;
        });
      }
    },
    [key, setParams]
  );

  useLayoutEffect(() => {
    const currentValue = params.get(key);
    if (!currentValue && initialDate) {
      setParams((p) => {
        p.set(key, initialDate.format("YYYY-MM-DD"));
        return p;
      });
    }
  }, [key, initialDate, params, setParams]);

  return (
    <Grid item key={key} xs={extraSmallSize} sm={smallSize}>
      <DatePickerUncontrolled
        value={currentValue ? dayjs(currentValue) : null}
        onChange={onChangeHandler}
        isClearable={filter.isClearable}
        label={label}
        fullwidth
      />
    </Grid>
  );
});
