import React, { createContext, useContext } from "react";
import {
  IPrivilegesContextProps,
  IPrivilegesContextProviderProps,
} from "./models";

export const PrivilegesContext = createContext<IPrivilegesContextProps>({
  privileges: [],
});

export const PrivilegesContextProvider: React.FC<
  IPrivilegesContextProviderProps
> = ({ children, privileges }) => {
  return (
    <PrivilegesContext.Provider
      value={{
        privileges,
      }}
    >
      {children}
    </PrivilegesContext.Provider>
  );
};

export const UsePrivileges = () => {
  const context = useContext(PrivilegesContext);

  return context;
};
