import PagesContainer from "../../components/UI/PagesContainer";
import { useRequest } from "../../hooks/useRequest/useRequets";
import { listContentHelp } from "../../services/contentHelp.service";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import HelpLoading from "./HelpLoading";
import HelpAccordion from "../../components/HelpContent/HelpAccordion";
import NoData from "../../components/UI/NoData/NoData";

const HelpPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, isLoading } = useRequest({
    queryKey: ["help-contents"],
    request: listContentHelp,
    requestBody: "",
  });

  return (
    <PagesContainer title="Ajuda" width={"100%"} loading={isLoading}>
      <Box
        mt={5}
        pr={matches ? undefined : "20%"}
        height={"100%"}
        width={"100%"}
        overflow={"auto"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
      >
        {isLoading && <HelpLoading />}
        {(!data || data.length === 0) && !isLoading && <NoData />}
        {data &&
          !isLoading &&
          data.map((help) => (
            <HelpAccordion
              title={help.title}
              content={help.content}
              key={help.id}
            />
          ))}
      </Box>
    </PagesContainer>
  );
};

export default HelpPage;
