import React from "react";
import { Navigate } from "react-router-dom";
import { IControlAccessProps } from "./models";

export const ControlAccess: React.FC<IControlAccessProps> = ({
  canAccess,
  children,
  redirectUrl,
}) => {
  if (canAccess()) return children;
  return <Navigate to={redirectUrl} />;
};
