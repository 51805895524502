import { Tab, Tabs } from "@mui/material";
import { ITabsComponentProps } from "./models";

export const TabsComponent = ({
  value,
  tabs,
  handleChange,
}: ITabsComponentProps) => {
  return (
    <Tabs value={value} onChange={handleChange}>
      {tabs.map((tab) => (
        <Tab
          label={tab.label}
          tabIndex={tab.tabIndex}
          iconPosition={tab.iconPosition ?? "start"}
          icon={tab.icon}
          sx={{ minHeight: 0 }}
        />
      ))}
    </Tabs>
  );
};
