import { ISelectCompany } from "models/companies";
import { GlobalAxios } from "./../axios/axios-config";

const COMPANY_ROUTE = "/api/company";

export const companiesList = (params?: any) => {
  return GlobalAxios.get<ISelectCompany[]>(COMPANY_ROUTE, {
    params: params,
  });
};
