import React from "react";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { Control, Path, useWatch } from "react-hook-form";

const FormContainerSubtitle = <T extends object>({
  watchField,
  control,
  onChange,
}: {
  watchField: Path<T>;
  control: Control<T, any>;
  onChange: (watch: string) => void;
}) => {
  const subtitle = useWatch({ name: watchField, control });

  useEffect(() => {
    if (subtitle) onChange(subtitle);
    if (!subtitle || subtitle === undefined) onChange("");
  }, [subtitle, onChange]);

  return (
    <Typography
      variant="subtitle1"
      mb={2}
      color={(theme) => theme.palette.text.secondary}
    >
      {subtitle}
    </Typography>
  );
};

export default FormContainerSubtitle;
