import { ListItemButton } from "@mui/material";
import { alpha } from "@mui/material/styles";

const CustomListItemButton = ({
  active,
  children,
  height,
  onClick,
}: {
  active: boolean;
  children: any;
  height?: number;
  onClick: () => void;
}) => {
  return (
    <ListItemButton
      sx={{
        height: height ? height : 44,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 0.75,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        ...(active && {
          color: "primary.main",
          fontWeight: "bold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
      onClick={onClick}
    >
      {children}
    </ListItemButton>
  );
};

export default CustomListItemButton;
