import React from "react";

import { Dialog, Stack, Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IModalProps } from "./models";

/**
 * A simple modal wrap of mui dialog
 *
 * @param param  modal props
 * @returns an modal react component
 */
export const Modal: React.FC<IModalProps> = ({
  onClose,
  open,
  children,
  minWidth,
  maxWidth,
  dialogMaxWidth,
  title,
}) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={dialogMaxWidth}>
      <Box
        pb={2}
        minWidth={minWidth ? minWidth : undefined}
        maxWidth={maxWidth ? maxWidth : undefined}
      >
        <Stack
          direction={"row"}
          justifyContent={title ? "space-between" : "flex-end"}
          pt={title ? 2 : 1}
          px={title ? 2 : 1}
          pb={title ? 1 : 0}
        >
          {title && (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
          )}
          <IconButton aria-label="delete" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack spacing={2} px={2}>
          {children}
        </Stack>
      </Box>
    </Dialog>
  );
};
