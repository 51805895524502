import { FC } from "react";
import { SvgIconProps } from "@mui/material";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import FormatColorFillRoundedIcon from "@mui/icons-material/FormatColorFillRounded";

interface IDynamicIcon {
  icon?: string | null;
}

const DynamicIcon: FC<SvgIconProps & IDynamicIcon> = ({ icon }, props) => {
  switch (icon) {
    case "HomeRoundedIcon":
      return <HomeRoundedIcon {...props} />;
    case "PersonRoundedIcon":
      return <PersonRoundedIcon {...props} />;
    case "EventRoundedIcon":
      return <EventRoundedIcon {...props} />;
    case "EqualizerRoundedIcon":
      return <EqualizerRoundedIcon {...props} />;
    case "FormatColorFillRoundedIcon":
      return <FormatColorFillRoundedIcon {...props} />;
    default:
      return null;
  }
};

export default DynamicIcon;
