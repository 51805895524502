import { Modal } from "components/UI/Modal";
import { memo } from "react";
import { QualityForm } from "../EditQualityModal/QualityForm/QualityForm";

interface INewQualityModalProps {
  open: boolean;
  onClose: () => void;
}

export const NewQualityModal = memo(
  ({ open, onClose }: INewQualityModalProps) => {
    return (
      <Modal open={open} onClose={onClose} title="Qualidade">
        <QualityForm onClose={onClose} />
      </Modal>
    );
  }
);
