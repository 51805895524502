import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { NoAccessIllustration } from "../assets/illustrations";

const NoAccessPage = () => {
  const navigate = useNavigate();
  const urlParams = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <main style={{ width: !matches ? "50%" : "100%" }}>
        <Stack spacing={4} alignItems={"center"}>
          <img
            alt="Acesso negado imagem"
            style={{ maxWidth: 400 }}
            src={`data:image/svg+xml;base64,${NoAccessIllustration}`}
          />
          <Typography variant="h5">
            Ops! Parece que você não possui permissão para acessar esta página
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(`/e/${urlParams.eI}/`)}
          >
            Voltar ao início
          </Button>
        </Stack>
      </main>
    </Box>
  );
};

export default NoAccessPage;
