import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import FormContainerSubtitle from "./FormContainerSubtitle";
import { IFormContainer } from "./models";
// import { useRole } from "../../../../hooks/useRole";
import { Form } from "../Form";
import { BackButton } from "../../BackButton";
import { Loading } from "../../Loading";
import { UseEstablishmentContext } from "../../../../contexts/Establishment-Context/establishment-context";
import { motion } from "framer-motion";

export const FormContainer = <T extends object>({
  title,
  subtitle,
  getFunction,
  updateFunction,
  saveFunction,
  subtitleWatchField,
  loading,
  schema,
  defaultValues,
  fields,
  actionsBar = true,
  actionButton,
  cancelFunction,
  saveButtonLabel,
  saveRedirect = true,
  showButtons = true,
  backRoute,
  hideHeader = false,
}: IFormContainer<T>) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { pathname } = useLocation();
  const [getLoading, setGetLoading] = useState(id ? true : false);
  const [watchedField, setWatchedField] = useState<string>("");
  const { setUpdateEstablishment } = UseEstablishmentContext();

  const [pos, setPos] = useState<"moved" | "top">("top");

  const methods = useForm<T>({
    resolver: zodResolver(schema),
    defaultValues: !id ? defaultValues : undefined,
  });

  const { reset, handleSubmit, control } = methods;

  const content = useMemo(() => {
    return loading || getLoading ? (
      <Loading />
    ) : (
      fields(
        id && !pathname.includes("visualizar")
          ? "update"
          : id && pathname.includes("visualizar")
          ? "view"
          : "create",
        methods
      )
    );
  }, [loading, fields, id, getLoading, methods, pathname]);

  const fetchData = useCallback(
    async (id: string) => {
      if (!getFunction) return;
      setGetLoading(true);

      const { data, success } = await getFunction(id);
      setGetLoading(false);
      if (success) {
        reset(data);
      }
    },
    [getFunction, reset]
  );

  const updateData = useCallback(
    async (values: T) => {
      if (!updateFunction || !id) return;
      setGetLoading(true);
      const { data, success } = await updateFunction(values, id);
      setGetLoading(false);
      if (data && success) {
        if (saveRedirect) navigate(-1);
        if (window.location.href.includes("estabelecimentos")) {
          setUpdateEstablishment((prevState) => (prevState = prevState + 1));
        }
      }
    },
    [updateFunction, setUpdateEstablishment, navigate, id, saveRedirect]
  );

  const saveData = useCallback(
    async (values: T) => {
      if (!saveFunction) return;
      setGetLoading(true);
      const { data, success } = await saveFunction(values);
      setGetLoading(false);
      if (data && success) {
        if (saveRedirect) navigate(-1);
        if (window.location.href.includes("estabelecimentos")) {
          setUpdateEstablishment((prevState) => (prevState = prevState + 1));
        }
      }
    },
    [navigate, setUpdateEstablishment, saveFunction, saveRedirect]
  );

  useEffect(() => {
    if (getFunction && id) {
      fetchData(id);
    }
  }, [id, fetchData, getFunction]);

  const onSubmitHandler = useCallback(
    async (values: T) => {
      if (id && updateFunction) {
        updateData(values);
      } else {
        saveData(values);
      }
    },
    [saveData, updateData, id, updateFunction]
  );

  const backButtonHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    const main = document.getElementById("main");
    if (main) {
      main.addEventListener("scroll", (e) => {
        let scrolled = main.scrollTop;
        if (scrolled && scrolled >= 5) {
          setPos("moved");
        } else {
          setPos("top");
        }
      });
    }
  }, []);

  const watchedFieldHandler = useCallback((watch: string) => {
    if (watch) setWatchedField(watch);
    if (watch === "") setWatchedField("");
  }, []);

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Stack alignItems={"center"}>
          {!hideHeader && (
            <Stack
              alignItems={"start"}
              sx={(theme) => ({
                width: "100%",
                position: "sticky",
                top: 0,
                backgroundColor: theme.palette.background.default,
                zIndex: 2,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor:
                  pos === "moved" ? theme.palette.divider : "transparent",
                transition: "border-color 0.2s linear",
              })}
            >
              <Stack
                width={"100%"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <BackButton route={backRoute} />
                {actionButton && (
                  <Button
                    variant="contained"
                    startIcon={actionButton.startIcon}
                    onClick={actionButton.function}
                  >
                    {actionButton.label}
                  </Button>
                )}
              </Stack>
              <Stack sx={{ width: "80%" }} alignSelf={"center"}>
                <Typography variant="h5">{title}</Typography>
                {subtitleWatchField && (
                  <FormContainerSubtitle<T>
                    watchField={subtitleWatchField}
                    control={control}
                    onChange={watchedFieldHandler}
                  />
                )}
                {watchedField === "" && (
                  <Typography
                    variant="subtitle1"
                    mb={2}
                    color={(theme) => theme.palette.text.secondary}
                  >
                    {subtitle}
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}
          <Stack sx={{ width: "80%" }} mb={25} mt={5}>
            {content}
            {window.location.hostname === "localhost" && (
              <>
                <div style={{ marginBottom: "20px" }}>
                  {JSON.stringify(methods.getValues())}
                </div>
                <div style={{ marginBottom: "20px" }}>
                  {JSON.stringify(methods.formState.errors)}
                </div>
              </>
            )}
          </Stack>
          {actionsBar && !pathname.includes("visualizar") && showButtons && (
            <Paper
              sx={{
                position: "fixed",
                width: "70%",
                marginBottom: "10px",
                zIndex: 2,
                bottom: 45,
              }}
            >
              <motion.div
                initial={{ y: "110%" }}
                animate={{ y: 0 }}
                exit={{ y: "110%" }}
                transition={{ type: "spring", duration: 0.5 }}
              >
                <Stack direction={"row"} spacing={1} padding={1}>
                  <Button variant="contained" type="submit">
                    {saveButtonLabel ?? "Salvar"}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={cancelFunction ?? backButtonHandler}
                    type="button"
                  >
                    Cancelar
                  </Button>
                </Stack>
              </motion.div>
            </Paper>
          )}
        </Stack>
      </Box>
    </Form>
  );
};
