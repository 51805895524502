import { Grid } from "@mui/material";
import AutoCompleteGeneric from "components/UI/Form/AutocompleteGeneric/AutoCompleteGeneric";
import { IMarkup } from "models/price";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { markupList } from "services/price.service";

export const MarkupField = memo(() => {
  const { company } = useParams();

  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteGeneric<IMarkup>
        params={Number(company)}
        name="cost.markup"
        label="Markup"
        getOptionLabel={(option) => option.CD_DS}
        isOptionEqualToValue={(option, value) => option.ID_TAB === value.ID_TAB}
        size="small"
        request={markupList}
        fullWidth
      />
    </Grid>
  );
});
