import { ThemeProvider, createTheme } from "@mui/material";
import React, { useContext, useState } from "react";

import { createContext } from "react";
import { TAppreanceCode } from "../Config-Context";
import { IThemeContext, IThemeContextProviderProps } from "./models";
import { getThemeLocalStorage } from "../Config-Context/service";

export const ThemeContext = createContext<IThemeContext>({
  setMode: () => {},
});

export const ThemeContextProvider: React.FC<IThemeContextProviderProps> = ({
  children,
  themeOptions,
}) => {
  const [mode, setMode] = useState<TAppreanceCode>(
    getThemeLocalStorage() || "1"
  );
  const theme = createTheme(themeOptions(mode === "1" ? "light" : "dark"));

  return (
    <ThemeContext.Provider
      value={{
        setMode,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const UseThemeContext = () => {
  const context = useContext(ThemeContext);

  return context;
};
