import { useState, useEffect } from "react";

/**
 *
 * Get any value and only return it after the provided delay time with no value update.
 *
 * @see - https://www.freecodecamp.org/portuguese/news/debounce-como-atrasar-a-execucao-de-uma-funcao-em-javascript-exemplo-com-js-es6
 *
 * @param value Any value for debounce
 * @param milliSeconds debounce delay in miliseconds
 * @returns the debounced value
 */
export const useDebounce = (value: any, milliSeconds: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};
