import { PivotTable } from "components/UI/PivotTable/PivotTable";
import dayjs from "dayjs";
import { Field } from "devextreme/ui/pivot_grid/data_source";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { ISelectCompany } from "models/companies";
import { EBusinessSector } from "models/profitability";
import { IQuality } from "models/quality";
import { ISelectType } from "models/requests";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { companiesList } from "services/companies.service";
import { getAllProfitability } from "services/profitability.service";
import { THOUSANDS_SEPARATOR } from "utils/convertions";

const ProfitabilityPage = () => {
  const businessSectorOptions: ISelectType[] = [
    { id: EBusinessSector.CONFECCAO.toString(), name: "Confecção" },
    {
      id: EBusinessSector.TECELAGEM_TINTURARIA.toString(),
      name: "Tecelagem Tinturaria",
    },
    { id: EBusinessSector.TINTURARIA.toString(), name: "Tinturaria" },
  ];

  const [companies, setCompanies] = useState<ISelectCompany[]>([]);

  const { sendRequest } = useFnRequest(companiesList);

  const defaultEndDate = useMemo(() => new Date(), []);

  const defaultInitialDate = useMemo(() => new Date(), []);

  defaultEndDate.setHours(15);
  defaultInitialDate.setHours(15);
  defaultInitialDate.setDate(defaultEndDate.getDate() - 30);

  const columns: Field[] = [
    {
      caption: "Cliente",
      dataField: "cliente",
      area: "row",
      width: 250,
    },
    {
      dataField: "data",
      dataType: "date",
      area: "column",
      groupInterval: "month",
    },
    {
      dataField: "UF",
      dataType: "string",
      area: "column",
    },
    {
      caption: "Qtd.",
      dataField: "quantidadeFaturamento",
      dataType: "number",
      summaryType: "sum",
      area: "data",
      customizeText: (cellInfo) => {
        return cellInfo.value
          ? Number(cellInfo.value)
              .toFixed(0)
              .toString()
              .replace(THOUSANDS_SEPARATOR, ".")
          : "-";
      },
    },
    {
      caption: "Receita",
      dataField: "valorTotal",
      dataType: "number",
      summaryType: "sum",
      customizeText: (cellInfo) => {
        return cellInfo.value
          ? "R$" +
              Number(cellInfo.value)
                .toFixed(0)
                .toString()
                .replace(THOUSANDS_SEPARATOR, ".")
          : "-";
      },
      area: "data",
    },
    {
      caption: "M.C.",
      dataField: "margemContribuicao",
      dataType: "number",
      summaryType: "sum",
      customizeText: (cellInfo) => {
        return cellInfo.value
          ? "R$" +
              Number(cellInfo.value)
                .toFixed(0)
                .toString()
                .replace(THOUSANDS_SEPARATOR, ".")
          : "-";
      },
      area: "data",
      showGrandTotals: false,
    },
    {
      caption: "Lucro Líq.",
      dataField: "lucroLiquido",
      dataType: "number",
      summaryType: "sum",
      customizeText: (cellInfo) => {
        return cellInfo.value
          ? "R$" +
              Number(cellInfo.value)
                .toFixed(0)
                .toString()
                .replace(THOUSANDS_SEPARATOR, ".")
          : "-";
      },
      area: "data",
    },
  ];

  const fetchCompanies = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setCompanies(data);
    }
  }, [sendRequest]);

  useLayoutEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  return (
    <PivotTable<IQuality>
      title="Lucratividade"
      request={getAllProfitability}
      columns={columns}
      showsOnlyOneInChart
      filters={[
        {
          key: "company",
          label: "Empresa",
          type: "autocomplete",
          extraSmallSize: 12,
          smallSize: 3,
          onDefaultValue: (options) => options[0],
          isClearable: false,
          options: companies.map((d) => ({
            id: d.id.toString(),
            name: d.companyName,
          })),
        },
        {
          key: "businessSector",
          label: "Setor",
          type: "autocomplete",
          extraSmallSize: 12,
          smallSize: 2,
          onDefaultValue: (options) =>
            options.find(
              (opt) => opt.id === EBusinessSector.TINTURARIA.toString()
            ) ?? options[0],
          isClearable: false,
          options: businessSectorOptions,
        },
        {
          key: "startDate",
          label: "Data de início",
          type: "date",
          extraSmallSize: 12,
          smallSize: 2,
          isClearable: false,
          initialDate: dayjs(defaultInitialDate),
        },
        {
          key: "endDate",
          label: "Data de fim",
          type: "date",
          extraSmallSize: 12,
          smallSize: 2,
          isClearable: false,
          initialDate: dayjs(defaultEndDate),
        },
      ]}
    />
  );
};

export default ProfitabilityPage;
