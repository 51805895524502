import { TabPanel } from "components/UI/Tabs/TabPanel/TabPanel";
import { memo, useCallback, useState } from "react";
import { IActionsProps, TRender } from "./models";
import { QualityActionsProvider } from "contexts/Quality-Actions-Context/quality-actions-context";
import { ActionsList } from "./ActionsList/ActionsList";
import { ActionForm } from "./ActionForm/ActionForm";
import { ICurrentActionProps } from "./ActionsList/models";

export const Actions = memo(({ value, id, squa }: IActionsProps) => {
  const [render, setRender] = useState<TRender>("list");

  const [action, setAction] = useState<ICurrentActionProps | null>(null);

  const changeRenderHandler = useCallback((value: TRender) => {
    setRender(value);
  }, []);

  const getActionHandler = useCallback((action: ICurrentActionProps | null) => {
    setAction(action);
  }, []);

  return (
    <TabPanel value={value} index={1}>
      <QualityActionsProvider id={id} squa={squa}>
        <>
          {render === "list" && (
            <ActionsList
              onChangeRender={changeRenderHandler}
              onGetActionHandler={getActionHandler}
            />
          )}
          {render === "form" && (
            <ActionForm
              id={id}
              squa={squa}
              currentAction={action}
              onChangeRender={changeRenderHandler}
            />
          )}
        </>
      </QualityActionsProvider>
    </TabPanel>
  );
});
