import React, { memo } from "react";
import {
  Box,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

interface ILoadingProps {
  size?: number;
  sx?: SxProps<Theme>;
}

export const Loading: React.FC<ILoadingProps> = memo(({ sx, size = 100 }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        zIndex: 100,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
        top: 0,
        left: 0,
        ...sx,
      }}
    >
      <CircularProgress size={size} />
      <Typography color="textSecondary">Carregando...</Typography>
    </Box>
  );
});
