import { GlobalAxios } from "./../axios/axios-config";
import { IProfitabilityParams } from "models/profitability";

const PROFITABILITY_ROUTE = "api/profitability/pivotGrid";

export const getAllProfitability = (params: IProfitabilityParams) => {
  return GlobalAxios.get(PROFITABILITY_ROUTE, {
    params,
  });
};
