import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { authService } from "../services/auth.service";
import { useCallback, useState } from "react";
import { useFnRequest } from "../hooks/useFnRequest/useFnRequest";
import ForgetPasswordForm, {
  TForgotPassword,
} from "../components/Auth/ForgetPasswordForm/ForgetPasswordForm";
import { Typography } from "@mui/material";
import { Button } from "../components/UI/Button";
import { useNavigate } from "react-router-dom";

const ForgetPasswordPage = () => {
  const { sendRequest, loading } = useFnRequest(authService.forgotPassword);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const onSubmitHandler = useCallback(
    async (values: TForgotPassword) => {
      const { success } = await sendRequest({
        ...values,
      });
      if (success) {
        setEmailSent(true);
      }
    },
    [sendRequest]
  );

  if (emailSent) {
    return (
      <Box
        sx={(theme) => ({
          height: "100vh",
          backgroundColor: theme.palette.background.default,
        })}
      >
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Card
            sx={(theme) => ({
              p: 5,
              width: 1,
              maxWidth: 380,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: theme.palette.background.paper,
            })}
          >
            <Typography variant="h4" textAlign="center" fontWeight="bold">
              ACCL
            </Typography>
            {/* <img
              style={{ width: 300 }}
              src="/gera_lead_horizontal_logo.png"
              alt="Logo Gera Lead"
            /> */}

            <Typography mt={5} variant="h6">
              E-mail enviado com sucesso!
            </Typography>

            <Typography mt={5} variant="subtitle2" textAlign={"center"}>
              Verifique seu e-mail e siga o passo a passo para resetar sua
              senha.
            </Typography>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 4 }}
              onClick={() => navigate(-1)}
            >
              Voltar para o login
            </Button>
          </Card>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        height: "100vh",
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={(theme) => ({
            p: 5,
            width: 1,
            maxWidth: 380,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <img style={{ width: 225 }} src="/logo.png" alt="Logo Pygg" />
          <ForgetPasswordForm loading={loading} onSubmit={onSubmitHandler} />
        </Card>
      </Stack>
    </Box>
  );
};

export default ForgetPasswordPage;
