import { IGetClient, IMarkup, IPayment, IProfit, ITax } from "models/price";
import { GlobalAxios } from "./../axios/axios-config";
import { ISelectCollection, ISelectCollectionParams } from "models/collection";

const PRICE_ROUTE = "api/price/";

export const getClientByCode = (code: number) => {
  return GlobalAxios.get<IGetClient[]>(PRICE_ROUTE + `client/${code}`);
};

export const markupList = (company: number) => {
  return GlobalAxios.get<IMarkup[]>(PRICE_ROUTE + "markup", {
    params: { company },
  });
};

export const paymentList = (company: number) => {
  return GlobalAxios.get<IPayment[]>(PRICE_ROUTE + "payment", {
    params: { company },
  });
};

export const profitList = () => {
  return GlobalAxios.get<IProfit[]>(PRICE_ROUTE + "profit");
};

export const getTaxes = (company: number) => {
  return GlobalAxios.get<ITax[]>(PRICE_ROUTE + "taxes", {
    params: { company },
  });
};

export const collectionList = (params?: ISelectCollectionParams) => {
  return GlobalAxios.get<ISelectCollection[]>(
    PRICE_ROUTE + "collection/filter",
    {
      params: params,
    }
  );
};
