import { removeUserData } from "../axios/axios-config";
import { TokenToStorage } from "../models/user";
import { tokenService } from "./token.service";

const ESTABLISHMENT_KEY = "Establishment.Code";

const urlWithEChanged = (e: string): string | undefined => {
  const splitedUrl = window.location.href.split("/");
  const eIndex = splitedUrl.findIndex((u) => u === "e");

  if (eIndex === -1) {
    return;
  }
  splitedUrl[eIndex + 1] = e;

  return splitedUrl.join("/");
};

export const getEstablishmentToken = (
  removeUserData: () => void,
  establishmentcode?: number,
  token?: TokenToStorage
) => {
  if (establishmentcode && !token) {
    if (establishmentcode === Number(getEstablishmentCode())) {
      removeUserData();
    }
    const newUrl = urlWithEChanged(getEstablishmentCode() || "0");
    if (newUrl) {
      window.location.replace(newUrl);
    }
  }

  if (establishmentcode !== undefined && token) {
    saveEstablishmentCode(establishmentcode);
  }

  return token;
};

export const getEIFromUrl = (): number | undefined => {
  const splitedUrl = window.location.href.split("/");
  const eIndex = splitedUrl.findIndex((u) => u === "e");

  if (eIndex === -1) {
    return;
  }

  return Number(splitedUrl[eIndex + 1]);
};

export const getEstToken = () => {
  const establishmentcode = getEIFromUrl();

  let token = tokenService
    .getAccessTokens()
    .find((t) => t.code === Number(establishmentcode));

  return getEstablishmentToken(removeUserData, establishmentcode, token);
};

export const saveEstablishmentCode = (code: number) => {
  sessionStorage.setItem(ESTABLISHMENT_KEY, String(code));
  localStorage.setItem(ESTABLISHMENT_KEY, String(code));
};

export const getEstablishmentCode = () =>
  sessionStorage.getItem(ESTABLISHMENT_KEY) ||
  localStorage.getItem(ESTABLISHMENT_KEY);

export const clearEstablishmentCode = () => {
  sessionStorage.removeItem(ESTABLISHMENT_KEY);
  localStorage.removeItem(ESTABLISHMENT_KEY);
};
