import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNotificationContext } from "contexts/Notification-Context";
import DataGrid, {
  Column,
  DataGridTypes,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { useRequest } from "hooks/useRequest/useRequets";
import { useConfirm } from "material-ui-confirm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { Filters } from "./Filters/Filters";
import { IPivotDataGridProps } from "./models";

const PivotDataGrid = <T extends object>({
  title,
  columns,
  actionButton,
  filters,
  rowModalTitle,
  confirmOptions,
  showPagination = true,
  hideDelete = false,
  showBackButton = false,
  serviceProps,
  customKey,
  onDelete,
  request,
  onRowClick,
  onEdit,
}: IPivotDataGridProps<T>) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { setMessage } = useNotificationContext();

  const queryClient = useQueryClient();

  const confirm = useConfirm();

  const [collapsed, setCollapsed] = useState(true);

  const [open, setOpen] = useState(false);

  const [rowData, setRowData] = useState<DataGridTypes.RowClickEvent<T> | null>(
    null
  );

  const [params] = useSearchParams();

  const [refresh, setRefresh] = useState(0);

  const initialFilter = useMemo(
    () => Object.fromEntries(params.entries()),
    [params]
  );

  const [isFilterLoading, setIsFilterLoading] = useState(true);

  const [filterParams, setFilterParams] = useState<Record<string, any> | null>(
    null
  );

  const filterHandler = useCallback(
    (params: Record<string, any>) => {
      setRefresh((prev) => prev + 1);
      setFilterParams({ ...params, refresh: refresh });
    },
    [refresh]
  );

  const pageSizes = [10, 25, 50, 100];

  useEffect(() => {
    const loadInitialFilter = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsFilterLoading(false);
    };

    loadInitialFilter();
  }, []);

  const hasAllFilters = useMemo(() => {
    if (!filters) return true;
    if (params.size === filters.length) {
      return true;
    } else {
      return false;
    }
  }, [params, filters]);

  const { data } = useRequest({
    queryKey: ["datagrid-request", filterParams, filters, serviceProps],
    requestBody: filters
      ? filterParams
        ? { ...filterParams, ...serviceProps }
        : { ...initialFilter, ...serviceProps }
      : serviceProps,
    request: request,
    permission: filters
      ? (!!filterParams || !!initialFilter) && !isFilterLoading && hasAllFilters
      : true,
  });

  const deleteHandler = useCallback(
    (e: any, data: T) => {
      e.stopPropagation();
      confirm({
        ...confirmOptions,
        title: confirmOptions
          ? confirmOptions.title
          : "Tem certeza que deseja excluir?",
        description: confirmOptions
          ? confirmOptions.description
          : "Essa ação irá excluir o registro permanentemente.",
        cancellationText: "Cancelar",
        confirmationText: "Sim, excluir",
      })
        .then(async () => {
          if (onDelete) {
            const result = await onDelete(data);
            if (result) {
              queryClient.invalidateQueries({ queryKey: ["datagrid-request"] });
              setMessage({
                message: "Registro excluído com sucesso",
                type: "success",
              });
            }
          }
        })
        .catch(() => {});
    },
    [confirm, onDelete, setMessage, queryClient, confirmOptions]
  );

  const editHandler = useCallback(
    (e: any, data: T) => {
      e.stopPropagation();
      if (!onEdit) return;
      onEdit(data);
    },
    [onEdit]
  );

  const onContentReady = useCallback(
    (e: DataGridTypes.ContentReadyEvent) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    },
    [collapsed]
  );

  const cellPreparedHandler = (e: DataGridTypes.CellPreparedEvent<T, any>) => {
    const elem = e.cellElement;
    elem.style.cursor = "pointer";
    elem.style.border = "none";
    elem.style.borderBottom = `1px solid ${
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 700]
    }`;
    if (e.columnIndex !== columns.length) {
      elem.style.borderRight = `1px solid ${
        theme.palette.mode === "light" ? theme.palette.divider : "#fff"
      }`;
    }
    elem.style.backgroundColor =
      theme.palette.mode === "light"
        ? "transparent"
        : theme.palette.background.paper;
    if (e.columnIndex === 0) {
      elem.style.borderTopLeftRadius = "10px";
    }
    if (e.columnIndex === columns.length) {
      elem.style.borderTopRightRadius = "10px";
    }
    elem.style.color = theme.palette.text.primary;
  };

  const onRowPrepared = (e: DataGridTypes.RowPreparedEvent<T, any>) => {
    e.rowElement.style.height = "40px";
  };

  const onRowClickHandler = useCallback((e: DataGridTypes.RowClickEvent<T>) => {
    setRowData(e);
    setOpen(true);
  }, []);

  const onClosedHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const showActionColumn = !hideDelete || onEdit;

  return (
    <>
      <Modal
        open={open}
        onClose={onClosedHandler}
        title={rowModalTitle}
        dialogMaxWidth="sm"
      >
        {rowData && onRowClick
          ? onRowClick(rowData, onClosedHandler)
          : "Nenhum conteúdo"}
      </Modal>
      <Stack
        sx={{ mt: 1, position: "relative", height: "100%", overflow: "hidden" }}
      >
        <Paper
          sx={(t) => ({
            p: 2,
            width: "100%",
            height: "100%",
            backgroundColor: t.palette.mode === "light" ? "#fff" : undefined,
            overflow: "hidden",
          })}
          component={Box}
          elevation={0}
        >
          <Stack sx={{ mb: 2, width: "100%" }}>
            <Stack
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {showBackButton && (
                  <Tooltip title="Voltar">
                    <Stack>
                      <Button
                        size="small"
                        onClick={backHandler}
                        sx={{ minWidth: 0 }}
                      >
                        <ArrowBackIosNewRoundedIcon fontSize="small" />
                      </Button>
                    </Stack>
                  </Tooltip>
                )}
                <Typography variant="h6">{title}</Typography>
              </Stack>
              {actionButton && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={actionButton.startIcon}
                  onClick={actionButton.onClick}
                >
                  {actionButton.label}
                </Button>
              )}
            </Stack>
            <Filters filters={filters} onFilterHandler={filterHandler} />
          </Stack>
          <DataGrid
            dataSource={data ?? []}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            width="100%"
            keyExpr={customKey}
            height={
              showPagination
                ? "calc(100% - 80px)"
                : !showPagination && !filters
                ? "calc(100% - 50px)"
                : "calc(100% - 95px)"
            }
            scrolling={{ mode: "standard" }}
            onContentReady={onContentReady}
            hoverStateEnabled
            onCellPrepared={cellPreparedHandler}
            onRowClick={onRowClick && onRowClickHandler}
            onRowPrepared={onRowPrepared}
            noDataText="Nenhum registro encontrado"
            className="custom-pagination"
          >
            {showActionColumn && (
              <Column
                dataField={"deleleteAction"}
                alignment={"left"}
                caption=""
                width={onEdit && !hideDelete ? 75 : 45}
                cellRender={(e) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {onEdit && (
                        <IconButton
                          size="small"
                          onClick={(event) => editHandler(event, e.data)}
                        >
                          <ModeEditRoundedIcon fontSize="small" />
                        </IconButton>
                      )}
                      {!hideDelete && (
                        <IconButton
                          size="small"
                          onClick={(event) => deleteHandler(event, e.data)}
                        >
                          <DeleteRoundedIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  );
                }}
                cssClass={"custom-cell"}
              />
            )}
            {columns.map((column) => (
              <Column
                dataField={column.dataField}
                caption={column.caption}
                dataType={column.dataType}
                format={column.format}
                alignment={column.alignment ?? "left"}
                allowGrouping={column.allowGrouping}
                width={column.width}
                key={column.dataField}
                cssClass={"custom-cell"}
                cellRender={column.renderCell}
              />
            ))}
            <Pager
              visible={showPagination}
              allowedPageSizes={pageSizes}
              showPageSizeSelector={true}
              showNavigationButtons={true}
              showInfo={true}
              displayMode="compact"
              infoText="Página {0} de {1} ({2} itens)"
            />
            <Paging defaultPageSize={25} enabled={showPagination} />
          </DataGrid>
        </Paper>
      </Stack>
    </>
  );
};

export default PivotDataGrid;
