import { IconButton, Tooltip } from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { getEIFromUrl } from "../../../services/establishment.service";

const HelpButton = () => {
  const navigate = useNavigate();

  const eI = getEIFromUrl();

  const { pathname } = useLocation();

  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (!event) return;
    if (event.button === 1) {
      window.open(`/e/${eI}/ajuda`);
    } else {
      navigate("ajuda");
    }
  };

  return (
    <Tooltip title="Ajuda">
      <IconButton onClick={clickHandler} onMouseDown={clickHandler}>
        <HelpRoundedIcon
          color={pathname === `/e/${eI}/ajuda` ? "primary" : undefined}
        />
      </IconButton>
    </Tooltip>
  );
};

export default HelpButton;
