import { useCallback, useState } from "react";
import { IOptions, Trequest, Treturn } from "./models";
import { useAuthContext } from "../../contexts/Auth-Context";
import { useNotificationContext } from "../../contexts/Notification-Context";

/**
 *
 * @param request an axios request
 * @param options options for the hooke
 * @returns an error, loading and request to use
 *
 * @example
 *
 * const { sendRequest, loading , error } = useFetch(someRequest);
 *
 *  loading // an boolean to indiciate if the request is loading
 *  error // an object containg request error
 *  sendRequest // and function to send the request
 *
 */
export const useFetch = <T, X>(
  request?: Trequest<T, X>,
  options?: IOptions
) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(options?.startLoadingTrue || false);

  const authContext = useAuthContext();
  const notificationContext = useNotificationContext();

  if (authContext === null) {
    throw new Error("No AuthContext provided");
  }

  if (notificationContext === null) {
    throw new Error("No NotificationContext provided");
  }

  const { setMessage } = notificationContext;
  const { onLogoutUser } = authContext;

  const sendRequest: (requestBody: T) => Promise<Treturn<X>> = useCallback(
    async (requestBody: T) => {
      if (!request)
        return {
          success: false,
          data: null,
        };
      try {
        setLoading(true);
        const response = await request(requestBody);
        setLoading(false);
        // no content return
        if (response.status === 204) {
          return {
            success: false,
            data: null,
          };
        }
        return {
          success: true,
          data: response.data,
        };
      } catch (err: any) {
        if (err.message) {
          setMessage({
            message: err.message,
            type: "error",
          });

          return {
            success: false,
            data: null,
          };
        }
        if (err?.response) {
          if (err?.response?.status === 401) {
            onLogoutUser();
          }
          if (err.response.data.message) {
            if (err.response.data.message.length > 1) {
              setMessage({
                message: err.response.data.message.join(" | "),
                type: "error",
              });
            }

            if (err.response.data.message.length === 1) {
              setMessage({
                message: err.response.data.message[0],
                type: "error",
              });
            }

            return {
              success: false,
              data: null,
            };
          }
          setError(err.response?.data);
          if (err.response.data.message) {
            setMessage({
              message:
                err.response.data.message === "User not found"
                  ? "Usuário não encontrado"
                  : err.response.data.message,
              type: "error",
              notificationKey: new Date().getMilliseconds(),
            });
            return {
              success: false,
              data: null,
            };
          }
          if (err.response?.data.errors) {
            let errorMessage = "";

            Object.keys(err.response?.data.errors).forEach((key) => {
              errorMessage += err.response?.data.errors[key].join(" | ") + " ";
            });

            setMessage({
              message: errorMessage,
              type: "error",
              notificationKey: new Date().getMilliseconds(),
            });

            return {
              success: false,
              data: null,
            };
          }
          if (err.response?.data.detail) {
            setMessage({
              message: err.response?.data.detail || err.message,
              type: "error",
              notificationKey: new Date().getMilliseconds(),
            });
            return {
              success: false,
              data: null,
            };
          }
          setMessage({
            message: err.message,
            type: "error",
            notificationKey: new Date().getMilliseconds(),
          });
          return {
            success: false,
            data: null,
          };
        } else {
          setMessage({
            message: err.message,
            type: "error",
            notificationKey: new Date().getMilliseconds(),
          });
        }
        return {
          success: false,
          data: null,
        };
      } finally {
        setLoading(false);
      }
    },
    [request, setMessage, onLogoutUser]
  );

  return { error, loading, sendRequest };
};
