import { Grid } from "@mui/material";
import { DateInput } from "components/UI/Form/DateInput";
import { FormCheckBox } from "components/UI/Form/FormCheckBox";
import { FormInput } from "components/UI/Form/FormTextInput";
import { memo, useCallback } from "react";
import { IQualityFieldsProps } from "./models";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { useFormContext } from "react-hook-form";
import { TQualityForm } from "../qualitySchema";
import { getClientByCode } from "services/price.service";

export const QualityFields = memo(({ id }: IQualityFieldsProps) => {
  const { setValue } = useFormContext<TQualityForm>();

  const { sendRequest } = useFnRequest(getClientByCode);

  const fetchClientData = useCallback(
    async (code: number) => {
      const { data, success } = await sendRequest(code);
      if (data && success) {
        setValue("clientName", data[0].CLIENT);
      }
    },
    [sendRequest, setValue]
  );

  const onBlurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      if (e.target.value.length > 0) {
        fetchClientData(Number(e.target.value));
      }
    },
    [fetchClientData]
  );

  return (
    <>
      <Grid item xs={12} sm={4}>
        <FormInput
          label="Partida"
          name="clientId"
          size="small"
          disabled={!!id}
          autoFocus
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateInput
          label="Data"
          name="date"
          initialDate={new Date()}
          isClearable={false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInput
          label="Cod Cliente"
          name="code"
          size="small"
          onBlur={onBlurHandler}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormInput
          label="Nome do Cliente"
          name="clientName"
          size="small"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormInput label="Artigo" name="article" size="small" fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormInput
          label="Defeito"
          name="description"
          size="small"
          multiline
          minRows={8}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInput
          label="Responsável"
          name="responsible"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} textAlign={"end"}>
        <FormCheckBox name="resolv" label="Resolvido" size="small" />
      </Grid>
    </>
  );
});
