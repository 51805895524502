import React, { useContext, useState } from "react";

import { createContext } from "react";

interface IEstablishmentContext {
  updateEstablishment: number;
  setUpdateEstablishment: React.Dispatch<React.SetStateAction<number>>;
}

interface IEstablishmentContextProviderProps {
  children: any;
}

export const EstablishmentContext = createContext<IEstablishmentContext>({
  updateEstablishment: 0,
  setUpdateEstablishment: () => {},
});

export const EstablishmentContextProvider: React.FC<
  IEstablishmentContextProviderProps
> = ({ children }) => {
  const [updateEstablishment, setUpdateEstablishment] = useState<number>(0);

  return (
    <EstablishmentContext.Provider
      value={{
        updateEstablishment,
        setUpdateEstablishment,
      }}
    >
      {children}
    </EstablishmentContext.Provider>
  );
};

export const UseEstablishmentContext = () => {
  const context = useContext(EstablishmentContext);

  return context;
};
