import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { IHelpAccordionProps } from "./models";
import DisplayHtml from "../DisplayHtml/DisplayHtml";

const HelpAccordion = ({ title, content }: IHelpAccordionProps) => {
  return (
    <Accordion
      elevation={0}
      disableGutters
      variant="outlined"
      sx={{
        "&.MuiAccordion-root::before": {
          display: "none",
        },
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <DisplayHtml content={content} />
      </AccordionDetails>
    </Accordion>
  );
};

export default HelpAccordion;
