import { Box, Chip, Typography } from "@mui/material";
import { memo } from "react";
import { IFormDataProps } from "./models";

export const FormData = memo(
  ({ label, data, fontWeight = "normal", chipValue }: IFormDataProps) => {
    return (
      <Box
        sx={(t) => ({
          p: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderRadius: 1,
          transition: "ease-in-out 0.2s",
          "&:hover": {
            backgroundColor:
              t.palette.grey[t.palette.mode === "light" ? 200 : 700],
          },
        })}
      >
        <Typography
          sx={(t) => ({
            color:
              fontWeight === "normal"
                ? t.palette.grey[t.palette.mode === "light" ? 600 : 400]
                : undefined,
            fontWeight,
          })}
        >
          {label}:
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
          <Typography sx={{ fontWeight }}>{data}</Typography>
          {chipValue && <Chip size="small" label={chipValue} />}
        </Box>
      </Box>
    );
  }
);
