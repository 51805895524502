import { useState } from "react";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { tokenService } from "../../../services/token.service";
import {
  removePermissions,
  removeUserName,
} from "../../../services/user.service";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { useUser } from "../../../contexts/User-Context";
import { getEIFromUrl } from "../../../services/establishment.service";

const AccountPopover = () => {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const eI = getEIFromUrl();

  const { user } = useUser();

  const MENU_OPTIONS = [
    {
      label: "Perfil",
      icon: "eva:person-fill",
    },
    {
      label: "Configurações",
      icon: "eva:settings-2-fill",
    },
    {
      label: "Alterar senha",
      icon: "eva:settings-2-fill",
      onClick: () => navigate(`/e/${eI}/alterar-senha`),
    },
  ];

  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    tokenService.removeAllTokens();
    removeUserName();
    removePermissions();
    navigate("/login");
  };

  return (
    <>
      <IconButton
        onClick={(e) => handleOpen(e)}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => theme.palette.background.paper,
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          // src={account.photoURL}
          alt={user.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.paper}`,
          }}
        >
          {user && user.name && user.name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ p: 0, mt: 1 }}
      >
        <Box sx={{ my: 1.5, px: 2, display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {user.mail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed", marginBlock: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              if (option.onClick) {
                option.onClick();
              }
              handleClose();
            }}
          >
            <Badge
              badgeContent={"DEV"}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              invisible={!!option.onClick}
            >
              <Typography variant="subtitle2">{option.label}</Typography>
            </Badge>
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogout}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          Sair
        </MenuItem>
      </Popover>
    </>
  );
};

export default AccountPopover;
