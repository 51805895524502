import { Grid } from "@mui/material";
import AutoCompleteGeneric from "components/UI/Form/AutocompleteGeneric/AutoCompleteGeneric";
import { IPayment } from "models/price";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { paymentList } from "services/price.service";

export const PaymentField = memo(() => {
  const { company } = useParams();

  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteGeneric<IPayment>
        params={Number(company)}
        name="cost.payment"
        label="Condição de Pagamento"
        getOptionLabel={(option) => option.CD_DS}
        isOptionEqualToValue={(option, value) =>
          option.ID_PGTO === value.ID_PGTO
        }
        size="small"
        request={paymentList}
        fullWidth
      />
    </Grid>
  );
});
