import React, { createContext, useCallback, useContext, useState } from "react";
import { IMenuContextProps, IMenuContextProviderProps } from "./models";
import { IMenu } from "services/menus.service";

export const MenuContext = createContext<IMenuContextProps>({
  menus: [],
  onLoadMenus: () => {},
});

export const MenuContextProvider: React.FC<IMenuContextProviderProps> = ({
  children,
}) => {
  const [menus, setMenus] = useState<IMenu[]>([]);

  const onLoadMenus = useCallback((menus: IMenu[]) => {
    setMenus(menus);
  }, []);

  return (
    <MenuContext.Provider
      value={{
        menus,
        onLoadMenus,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);

  return context;
};
