import { memo, useCallback, useState } from "react";
import { Box, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { IActionDeleteButtonProps } from "./models";
import { useFnRequest } from "../../../../../../hooks/useFnRequest/useFnRequest";
import { deleteAction } from "../../../../../../services/actions.service";
import { Button } from "components/UI/Button";
import { useQueryClient } from "react-query";
import { useNotificationContext } from "contexts/Notification-Context";

export const ActionDeleteButton = memo(
  ({ company, sequenceAction, sequenceQuality }: IActionDeleteButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [open, setOpen] = useState(false);

    const { setMessage } = useNotificationContext();

    const queryClient = useQueryClient();

    const handleClick = (event: any) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    const { sendRequest, loading } = useFnRequest(deleteAction);

    const deleteHandler = useCallback(async () => {
      const { data, success } = await sendRequest({
        company: company,
        sequenceAction,
        sequenceQuality,
      });
      if (data && success) {
        handleClose();
        setMessage({ message: "Ação deletada com sucesso", type: "success" });
        queryClient.invalidateQueries(["actions-list"]);
      }
    }, [
      company,
      sequenceAction,
      sequenceQuality,
      queryClient,
      sendRequest,
      setMessage,
    ]);

    return (
      <>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography fontWeight="bold">Deletar ação?</Typography>
            <Typography
              variant="subtitle2"
              sx={(t) => ({
                color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
              })}
            >
              A ação será removida permanentemente
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "end",
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                size="small"
                disabled={loading}
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                size="small"
                color="error"
                disabled={loading}
                loading={loading}
                onClick={deleteHandler}
              >
                Deletar
              </Button>
            </Box>
          </Box>
        </Popover>
        <Tooltip title="Deletar ação" placement="left">
          <IconButton size="small">
            <DeleteRoundedIcon
              fontSize="small"
              color="error"
              onClick={handleClick}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  }
);
