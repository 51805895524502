import React, { createContext, useCallback, useContext, useState } from "react";
import { IMedia, IMediasContextProps } from "./models";

export const MediasContext = createContext<IMediasContextProps>({
  mediaFiles: [],
  slogan: null,
  fetchMediaFiles: () => {},
});

export const MediasContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [mediaFiles, setMediaFiles] = useState<IMedia[]>([]);
  const [slogan, setSlogan] = useState<string | null>(null);

  const fetchMediaFiles = useCallback(
    (medias: IMedia[], establishmentSlogan: string | null) => {
      setSlogan(establishmentSlogan);
      setMediaFiles(medias);
    },
    []
  );

  return (
    <MediasContext.Provider
      value={{
        mediaFiles,
        fetchMediaFiles,
        slogan,
      }}
    >
      {children}
    </MediasContext.Provider>
  );
};

export const useMedias = () => {
  const context = useContext(MediasContext);

  return context;
};
