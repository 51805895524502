import { Box, Skeleton } from "@mui/material";

const HelpLoading = () => {
  const skeletons = [0, 1, 2];

  return (
    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
      {skeletons.map((sk) => (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={50}
          sx={{ borderRadius: 1 }}
          key={sk}
        />
      ))}
    </Box>
  );
};

export default HelpLoading;
