import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import LoginForm, {
  TLoginFormData,
} from "../components/Auth/LoginForm/LoginForm";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/auth.service";
import { useCallback } from "react";
import { saveUserName } from "../services/user.service";
import { tokenService } from "../services/token.service";
import { useFnRequest } from "../hooks/useFnRequest/useFnRequest";

const LoginPage = () => {
  const navigate = useNavigate();
  const { sendRequest, loading } = useFnRequest(authService.login);

  const onSubmitHandler = useCallback(
    async (values: TLoginFormData) => {
      const { data } = await sendRequest({
        ...values,
        username: values.login,
      });
      if (data) {
        saveUserName(values.login);
        tokenService.saveTokens(data.token, data.refreshToken, "1");
        navigate(`/e/${1}`, {
          replace: true,
        });
      }
    },
    [sendRequest, navigate]
  );

  return (
    <Box
      sx={(theme) => ({
        height: "100vh",
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={(theme) => ({
            p: 5,
            width: 1,
            maxWidth: 380,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <img style={{ width: 225 }} src="/logo.png" alt="Logo Pygg" />
          <LoginForm onSubmit={onSubmitHandler} loading={loading} />
        </Card>
      </Stack>
    </Box>
  );
};

export default LoginPage;
