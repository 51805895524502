import PriceFormation from "pages/Commercial/PriceFormation/PriceFormationForm/PriceFormation";
import PriceFormationPage from "pages/Commercial/PriceFormation/PriceFormationPage";
import { PriceList } from "pages/Commercial/PriceFormation/PriceList/PriceList";
import ProfitabilityPage from "pages/Commercial/Profitability/ProfitabilityPage";
import { RouteObject } from "react-router-dom";

const CommercialRoutes: RouteObject[] = [
  {
    path: "formacao-preco",
    element: <PriceFormationPage />,
  },
  {
    path: "formacao-preco/:company/:collection/:clientId/:clientCode/:tabelaCustoInsumo/:tabelaCustoOperacao",
    element: <PriceList />,
  },
  {
    path: "formacao-preco/:company/new",
    element: <PriceFormation />,
  },
  {
    path: "lucratividade",
    element: <ProfitabilityPage />,
  },
];

export default CommercialRoutes;
