import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ChangeEvent, memo, useCallback, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ICheckboxFilterProps } from "./models";

export const CheckboxFilter = memo(({ filter }: ICheckboxFilterProps) => {
  const [params, setParams] = useSearchParams();

  const { key, extraSmallSize, smallSize, label } = filter;

  const toggleCheckHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: boolean) => {
      if (value) {
        setParams((p) => {
          p.set(key, "true");
          return p;
        });
      } else {
        setParams((p) => {
          p.set(key, "false");
          return p;
        });
      }
    },
    [key, setParams]
  );

  useLayoutEffect(() => {
    const currentValue = params.get(key);
    if (!currentValue && filter.initialChecked) {
      setParams((p) => {
        p.set(key, filter.initialChecked ? "true" : "false");
        return p;
      });
    }
  }, [params, key, filter.initialChecked, setParams]);

  return (
    <Grid item key={key} xs={extraSmallSize} sm={smallSize}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={params.get(key) === "true"}
            onChange={toggleCheckHandler}
          />
        }
        label={label}
      />
    </Grid>
  );
});
