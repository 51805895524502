import { Grid } from "@mui/material";
import { FormData } from "components/UI/Form/FormData/FormData";
import { useProduct } from "contexts/Product-Context/product-context";
import { AddProductActions } from "../AddProductActions/AddProductActions";
import { IFinancialDataProps } from "./models";
import { SuggestedPriceField } from "./SuggestedPriceField/SuggestedPriceField";
import { useFormContext, useWatch } from "react-hook-form";
import { TAddProductForm } from "../addProductSchema";
import { useRequest } from "hooks/useRequest/useRequets";
import { getTaxes } from "services/price.service";
import { useParams } from "react-router-dom";
import { memo, useEffect } from "react";
import { useSuggestedPrice } from "hooks/useSuggestedPrice/useSuggestedPrice";

const FinancialData = ({
  idFichaTecnica,
  loading,
  onClose,
}: IFinancialDataProps) => {
  const { step } = useProduct();

  const { company } = useParams();

  const { control, setValue } = useFormContext<TAddProductForm>();

  const { data } = useRequest({
    queryKey: ["get-taxes", company],
    requestBody: Number(company),
    request: getTaxes,
    permission: !!company,
  });

  useEffect(() => {
    if (!data) {
      setValue("cost.csll", 0);
      setValue("cost.ir", 0);
      return;
    }
    setValue("cost.csll", data[0].CSLL ?? 0);
    setValue("cost.ir", data[0].IR ?? 0);
  }, [data, setValue]);

  const productPrice = useWatch({
    name: "product.material",
    control,
  });

  const ir = useWatch({
    name: "cost.ir",
    control,
  });

  const csll = useWatch({
    name: "cost.csll",
    control,
  });

  const markup = useWatch({
    name: "cost.markup",
    control,
  });

  const conditionPayment = useWatch({
    name: "cost.payment",
    control,
  });

  const profit = useWatch({ name: "cost.profit", control });

  const { suggestedPrice } = useSuggestedPrice({
    netProfit: profit ? Number(profit.INDICE.toFixed(2)) : 0,
    conditionPayment: conditionPayment
      ? Number(conditionPayment.JUR_TOT.toFixed(2))
      : 0,
    cost:
      productPrice && productPrice.cost
        ? Number(productPrice.cost.toFixed(2))
        : 0,
    csll: csll ? Number(csll.toFixed(2)) : 0,
    ir: ir ? Number(ir.toFixed(2)) : 0,
    markup: markup ? Number(markup.MKP_TOT.toFixed(2)) : 0,
  });

  useEffect(() => {
    setValue("cost.grossProfit", suggestedPrice);
  }, [setValue, suggestedPrice]);

  if (step < 2 && !idFichaTecnica) return null;

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <FormData
          label="Preço Produto R$"
          data={productPrice ? `R$ ${suggestedPrice}` : "-"}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormData
          label="Margem Contribuição"
          data={
            markup && markup.MC_TOT ? `${markup.MC_TOT.toFixed(2)}%` : "0.00%"
          }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormData label="IR" data={ir ? `${ir}%` : "0.00%"} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormData label="CSLL" data={ir ? `${csll}%` : "0.00%"} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormData
          fontWeight="bold"
          label="Resultado Líquido"
          data={
            profit && profit.INDICE
              ? `${String(profit.INDICE.toFixed(2))}%`
              : "0.00%"
          }
        />
      </Grid>
      <SuggestedPriceField calculatedPrice={suggestedPrice} />
      <AddProductActions
        disabled={false}
        onCancel={onClose}
        loading={loading}
        typeSubmit
      />
    </Grid>
  );
};

export default memo(FinancialData);
