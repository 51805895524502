import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { IFiltersListProps } from "./models";
import { AutoCompleteFilter } from "../AutoCompleteFilter/AutoCompleteFilter";
import { DateFilter } from "../DateFilter/DateFilter";
import { CheckboxFilter } from "../CheckboxFilter/CheckboxFilter";

export const FiltersList = memo(({ filters }: IFiltersListProps) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexDirection: matches ? "column" : "row",
      }}
    >
      {filters.map((filter) => {
        switch (filter.type) {
          case "autocomplete":
            return <AutoCompleteFilter key={filter.key} filter={filter} />;
          case "date":
            return <DateFilter key={filter.key} filter={filter} />;
          case "checkbox":
            return <CheckboxFilter key={filter.key} filter={filter} />;
          default:
            return null;
        }
      })}
    </Grid>
  );
});
