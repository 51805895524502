import { IUseSuggestedPriceProps } from "./models";

export const useSuggestedPrice = ({
  netProfit,
  conditionPayment,
  cost,
  csll,
  ir,
  markup,
}: IUseSuggestedPriceProps) => {
  const grossProfit = netProfit / ((100 - (ir + csll)) / 100);

  const divider = (100 - (markup + conditionPayment + grossProfit)) / 100;

  const suggestedPrice = Number((cost / divider).toFixed(2));

  return {
    suggestedPrice,
    grossProfit,
  };
};
