import { TextField, useTheme } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomProps, IFormInputProps } from "./models";
import { IMaskInput } from "react-imask";
import { useLocation } from "react-router-dom";

const FormTextInput = React.forwardRef<any, CustomProps>(function FormTextInput(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      unmask={props.unmask}
      onChange={() => {}}
      onAccept={(value: string) => {
        return onChange({ target: { name: props.name, value: value } });
      }}
    />
  );
});

export const FormInput: FC<IFormInputProps> = ({
  name,
  mask,
  definitions,
  overwrite,
  unmask,
  startAdornment,
  endAdornment,
  maskType,
  ...otherProps
}) => {
  const formContext = useFormContext();
  const { pathname } = useLocation();
  const [phoneMask, setPhoneMask] = useState("(00)00000-0000");
  const [value, setValue] = useState("");
  const theme = useTheme();

  if (formContext === null) {
    throw new Error("No FormContext provided");
  }

  const { control } = formContext;

  useEffect(() => {
    if (maskType === "phone") {
      if (value.length !== 0) {
        if (value[4] === "9") {
          setPhoneMask("(00)00000-0000");
          return;
        }
        setPhoneMask("(00)0000-0000");
      } else {
        setPhoneMask("(00)00000-0000");
      }
    }
  }, [value, maskType]);

  const disabledStyles = {
    WebkitTextFillColor: pathname.includes("visualizar")
      ? theme.palette.text.primary
      : undefined,
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={""}
      render={({ fieldState: { error }, field }) => {
        const inputProps = {
          inputComponent: FormTextInput,
          inputProps: {
            mask: maskType === "phone" && !mask ? phoneMask : mask,
            unmask: unmask,
            definitions: definitions,
          },
          ...otherProps.InputProps,
        };

        return (
          <TextField
            {...field}
            onChange={(e) => {
              setValue(e.target.value);
              field.onChange(e);
            }}
            value={field.value || ""}
            error={!!error}
            InputProps={(mask && inputProps) || (maskType && inputProps)}
            helperText={error ? error.message : ""}
            disabled={pathname.includes("visualizar") || otherProps.disabled}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                ...disabledStyles,
              },
              ...otherProps.sx,
            }}
            {...otherProps}
          />
        );
      }}
    />
  );
};
