import { Navigate, Outlet, RouteObject } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import RootPage from "../pages/RootPage";
import WelcomePage from "../pages/WelcomePage";
import ErrorElement from "../pages/ErrorElement";
import { ControlAccess } from "../components/ControlAccess/ControlAccess";
import { tokenService } from "../services/token.service";
import { getEstablishmentCode } from "../services/establishment.service";
import NoAccessPage from "../pages/NoAccessPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ChangePasswordPage from "../pages/ChangePassword/ChangePasswordPage";
import HelpPage from "../pages/Help/HelpPage";
import PcpRoutes from "./pcp";
import CommercialRoutes from "./commercial";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to={`/e/${getEstablishmentCode()}`} />,
    errorElement: <ErrorElement />,
  },
  {
    path: "/e/:eI",
    element: (
      <ControlAccess
        canAccess={() => tokenService.hasAccessTokens()}
        redirectUrl="/login"
      >
        <RootPage />
      </ControlAccess>
    ),
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <WelcomePage />,
      },
      {
        path: "pcp",
        element: <Outlet />,
        children: PcpRoutes,
      },
      {
        path: "comercial",
        element: <Outlet />,
        children: CommercialRoutes,
      },
      {
        path: "no-access",
        element: <NoAccessPage />,
      },
      {
        path: "pagina-nao-encontrada",
        element: <ErrorElement />,
      },
      {
        path: "alterar-senha",
        element: (
          <ControlAccess
            canAccess={() => tokenService.hasAccessTokens()}
            redirectUrl="/"
          >
            <ChangePasswordPage />
          </ControlAccess>
        ),
      },
      {
        path: "ajuda",
        element: <HelpPage />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <ControlAccess
        canAccess={() => !tokenService.hasAccessTokens()}
        redirectUrl="/"
      >
        <LoginPage />
      </ControlAccess>
    ),
  },
  {
    path: "/esqueci-senha",
    element: <ForgetPasswordPage />,
  },
  {
    path: "/resetPassword/:token",
    element: <ResetPasswordPage />,
  },
];

export default routes;
