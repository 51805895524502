interface ICellConvertToCurrencyProps {
  value?: string | number | Date;
  valueText?: string;
}

export const cellConvertToCurrency = (
  cellInfo: ICellConvertToCurrencyProps
) => {
  if (cellInfo.value instanceof Date) return "-";

  if (!cellInfo.valueText) return "0";

  const value = Number(cellInfo.valueText).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return value;
};

export const THOUSANDS_SEPARATOR = /\B(?=(\d{3})+(?!\d))/g;
