import { TAppreanceCode } from "./models";

export const saveThemeLocalStorage = (code: TAppreanceCode) => {
  localStorage.setItem("theme", code);
};

export const getThemeLocalStorage = () => {
  const code = localStorage.getItem("theme");
  if (code) {
    return code as TAppreanceCode;
  }
};
