const DOMAIN_SESSION_STORAGE_KEY = "domain";
const DEFAULT_DEV_DOMAIN = "egadnet";

const setDomainInLocalStorage = (domain: string) => {
  localStorage.setItem(DOMAIN_SESSION_STORAGE_KEY, domain);
};

export const getDomainFromLocalStorage = () =>
  localStorage.getItem(DOMAIN_SESSION_STORAGE_KEY) || DEFAULT_DEV_DOMAIN;

export const getDomain = () => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const siteDomain = window.location.hostname;

  const isLocalHost = siteDomain === "localhost";

  const parameterDomain = urlParams.get("domain");

  if (isLocalHost) {
    if (parameterDomain) {
      setDomainInLocalStorage(parameterDomain);
      return parameterDomain;
    }

    console.log(getDomainFromLocalStorage());

    return getDomainFromLocalStorage();
  }

  if (!isLocalHost) {
    return siteDomain.split(".")[0];
  }

  return DEFAULT_DEV_DOMAIN;
};
