import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Chart, {
  AdaptiveLayout,
  ChartRef,
  CommonSeriesSettings,
  Size,
  Tooltip,
} from "devextreme-react/chart";

import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import PivotGrid, {
  FieldChooser,
  PivotGridRef,
} from "devextreme-react/pivot-grid";
import { locale } from "devextreme/localization";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { useRequest } from "hooks/useRequest/useRequets";
import { useSearchParams } from "react-router-dom";
import { THOUSANDS_SEPARATOR } from "utils/convertions";
import { Button } from "../Button";
import { Filters } from "../PivotDataGrid/Filters/Filters";
import { IPivotTable } from "./models";

const customizeTooltip = (args: any) => {
  const valueText = Number(args.originalValue)
    .toFixed(0)
    .toString()
    .replace(THOUSANDS_SEPARATOR, ".");
  return {
    html: `${args.seriesName}<br />Total<div>${valueText}</div>`,
  };
};

export const PivotTable = <T extends object>({
  title,
  columns,
  actionButton,
  defaultExpandedColumns = [],
  defaultExpandedRows = [],
  filters,
  showsOnlyOneInChart,
  request,
}: IPivotTable<T>) => {
  const chartRef = useRef<ChartRef>(null);

  const pivotGridRef = useRef<PivotGridRef>(null);

  const theme = useTheme();

  const [params] = useSearchParams();

  const [refresh, setRefresh] = useState(0);

  const initialFilter = useMemo(
    () => Object.fromEntries(params.entries()),
    [params]
  );

  const [isFilterLoading, setIsFilterLoading] = useState(true);

  const [filterParams, setFilterParams] = useState<Record<string, any> | null>(
    null
  );

  useEffect(() => {
    const loadInitialFilter = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsFilterLoading(false);
    };

    locale("pt-BR");

    loadInitialFilter();
  }, []);

  const filterHandler = useCallback(
    (params: Record<string, any>) => {
      setRefresh((prev) => prev + 1);
      setFilterParams({ ...params, refresh: refresh });
    },
    [refresh]
  );

  const hasAllFilters = useMemo(() => {
    if (!filters) return true;
    if (params.size === filters.length) {
      return true;
    } else {
      return false;
    }
  }, [params, filters]);

  const { data: sourceData } = useRequest({
    queryKey: ["profitability", filterParams],
    requestBody: filters ? filterParams ?? initialFilter : null,
    request: request,
    permission: filters
      ? (!!filterParams || !!initialFilter) && !isFilterLoading && hasAllFilters
      : true,
  });

  const dataSource = useMemo(
    () =>
      new PivotGridDataSource({
        fields: columns,
        store: sourceData ?? [],
      }),
    [columns, sourceData]
  );

  useEffect(() => {
    pivotGridRef.current?.instance().bindChart(chartRef.current?.instance(), {
      dataFieldsDisplayMode: "splitPanes",
      customizeChart: (chart: any) => {
        if (showsOnlyOneInChart) {
          const newChart = chart.panes[0];
          const newAxis = chart.valueAxis[0];
          return { axis: newAxis, panes: newChart };
        }

        return chart;
      },
    });
    setTimeout(() => {
      dataSource.expandHeaderItem("row", defaultExpandedRows);
      dataSource.expandHeaderItem("column", defaultExpandedColumns);
    });
  }, [
    dataSource,
    defaultExpandedRows,
    defaultExpandedColumns,
    showsOnlyOneInChart,
  ]);

  return (
    <Stack
      sx={{ mt: 1, position: "relative", height: "100%", overflow: "hidden" }}
    >
      <Paper
        sx={(t) => ({
          p: 2,
          width: "100%",
          height: "100%",
          backgroundColor: t.palette.mode === "light" ? "#fff" : undefined,
          overflow: "hidden",
        })}
        component={Box}
        elevation={0}
      >
        <Stack sx={{ mb: 4, width: "100%" }}>
          <Stack
            mb={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{title}</Typography>
              {actionButton && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={actionButton.startIcon}
                  onClick={actionButton.onClick}
                >
                  {actionButton.label}
                </Button>
              )}
            </Stack>
            <Filters filters={filters} onFilterHandler={filterHandler} />
          </Stack>
          <Chart ref={chartRef}>
            <Size height={300} />
            <Tooltip
              enabled={true}
              customizeTooltip={customizeTooltip}
              cornerRadius={5}
            />
            <CommonSeriesSettings type="bar" cornerRadius={5} />
            <AdaptiveLayout width={450} keepLabels />
          </Chart>
        </Stack>

        <PivotGrid
          id="pivotgrid"
          dataSource={dataSource}
          allowSortingBySummary={true}
          allowFiltering={true}
          showColumnTotals={false}
          showColumnGrandTotals={false}
          showRowTotals={false}
          showRowGrandTotals={false}
          width={"100%"}
          height={filters ? "calc(100% - 430px)" : "calc(100% - 375px)"}
          className={
            theme.palette.mode === "dark"
              ? "pivot-grid-dark custom-pivot-grid"
              : "custom-pivot-grid"
          }
          scrolling={{ mode: "virtual" }}
          ref={pivotGridRef}
        >
          <FieldChooser enabled height={600} />
        </PivotGrid>
      </Paper>
    </Stack>
  );
};
