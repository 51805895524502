import { useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import { IBackButtonProps } from "./models";

export const BackButton: React.FC<IBackButtonProps> = ({
  onlyIcon = false,
  route,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (route) {
      navigate(route);
    } else {
      navigate(-1);
    }
  };

  if (onlyIcon) {
    return (
      <IconButton onClick={handleClick}>
        <ArrowBackIosIcon />
      </IconButton>
    );
  }

  return (
    <Button
      variant="text"
      startIcon={<ArrowBackIosIcon />}
      onClick={handleClick}
    >
      Voltar
    </Button>
  );
};
