import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import { IDate } from "./models";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ptBR as portuguese } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { useLocation } from "react-router-dom";

export const DateInput = ({
  name,
  label,
  autoFocus = false,
  initialDate,
  isClearable = true,
  onChangeDate,
}: IDate) => {
  const { control } = useFormContext();
  const { pathname } = useLocation();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={initialDate ? initialDate : null}
      render={(field) => {
        return (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}
          >
            <DatePicker
              slotProps={{
                textField: {
                  size: "small",
                  error: !!field.fieldState.error,
                  fullWidth: true,
                },
                field: { clearable: isClearable },
              }}
              autoFocus={autoFocus}
              label={label}
              format={"dd/MM/yyyy"}
              disabled={pathname.includes("visualizar")}
              localeText={
                portuguese.components.MuiLocalizationProvider.defaultProps
                  .localeText
              }
              value={field.field.value ? new Date(field.field.value) : null}
              onChange={(v: any) => {
                if (onChangeDate) {
                  onChangeDate(new Date(v));
                }
                if (!v) {
                  field.field.onChange(null);
                  return;
                }
                field.field.onChange(new Date(v));
              }}
              sx={(t) => ({
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: pathname.includes("visualizar")
                    ? t.palette.text.primary
                    : undefined,
                },
              })}
            />
            <FormHelperText
              sx={(theme) => ({
                color: theme.palette.error.main,
                marginLeft: theme.spacing(2),
              })}
            >
              {field.fieldState.error ? field.fieldState.error?.message : ""}
            </FormHelperText>
          </LocalizationProvider>
        );
      }}
    />
  );
};
