import { Divider, Grid } from "@mui/material";
import { AutoComplete } from "components/UI/Form/Autocomplete";
import { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  compositionList,
  materialList,
  roadmapList,
} from "services/dyeing.service";
import { SelectColor } from "./SelectColor/SelectColor";
import { useProduct } from "contexts/Product-Context/product-context";
import { IProductFieldsProps } from "./models";
import { AddProductActions } from "../AddProductActions/AddProductActions";
import { useFormContext } from "react-hook-form";
import { TAddProductForm } from "../addProductSchema";
import { FormData } from "components/UI/Form/FormData/FormData";
import AutoCompleteGeneric from "components/UI/Form/AutocompleteGeneric/AutoCompleteGeneric";
import { ISelectProduct } from "models/product";

export const ProductFields = memo(
  ({ idFichaTecnica, onClose }: IProductFieldsProps) => {
    const { company } = useParams();

    const companyId = useMemo(() => Number(company), [company]);

    const { watch, setValue } = useFormContext<TAddProductForm>();

    const { step, onChangeStep } = useProduct();

    const searchHandler = useCallback(
      (v: string) => {
        return {
          searchBy: v,
          company: companyId,
        };
      },
      [companyId]
    );

    const nexStepHandler = useCallback(() => {
      onChangeStep(1);
    }, [onChangeStep]);

    const selectedMaterial = watch("product.material");

    const selectedComposition = watch("product.composicao");

    const selectedRoadmap = watch("product.roteiro");

    const selectedColor = watch("product.cor");

    const inputsFilled =
      selectedMaterial &&
      selectedComposition &&
      selectedRoadmap &&
      selectedColor;

    if (step !== 0 && !idFichaTecnica)
      return (
        <>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormData
                label="Produto"
                fontWeight="bold"
                data={selectedMaterial ? selectedMaterial.name : "-"}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormData
                label="Cor"
                data={selectedColor ? selectedColor.name : "-"}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormData
                label="Composição"
                data={selectedComposition ? selectedComposition.name : "-"}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormData
                label="Roteiro"
                data={selectedRoadmap ? selectedRoadmap.name : "-"}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
        </>
      );

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AutoCompleteGeneric<ISelectProduct>
            SearchAsYouTypeParams={searchHandler}
            name="product.material"
            label="Produto"
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onValueSelected={(value) =>
              setValue("cost.precoSugerido", value.cost.toFixed(2).toString())
            }
            request={materialList}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoComplete
            SearchAsYouTypeParams={searchHandler}
            name="product.composicao"
            label="Composição"
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            request={compositionList}
            size="small"
            fullWidth
          />
        </Grid>
        <SelectColor />
        <Grid item xs={12} sm={6}>
          <AutoComplete
            SearchAsYouTypeParams={searchHandler}
            name="product.roteiro"
            label="Roteiro"
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            request={roadmapList}
            size="small"
            fullWidth
          />
        </Grid>

        {!idFichaTecnica && (
          <AddProductActions
            onCancel={onClose}
            onNext={nexStepHandler}
            disabled={!inputsFilled}
          />
        )}
      </Grid>
    );
  }
);
