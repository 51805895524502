import { Grid } from "@mui/material";
import { FormContainer } from "components/UI/Form/FormContainer";
import {
  TPriceFormationForm,
  priceFormationSchema,
} from "./priceFormationSchema";
import AutoCompleteGeneric from "components/UI/Form/AutocompleteGeneric/AutoCompleteGeneric";
import { companiesList } from "services/companies.service";
import { ISelectCompany } from "models/companies";
import { collectionList } from "services/price.service";
import { useParams } from "react-router-dom";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { addCollectionToList } from "services/dyeing.service";
import { useCallback } from "react";
import { ISelectTypeCaptalize } from "models/requests";
import { GenericService } from "services/generics.service";

const CLIENT_ROUTE = "api/price/client/filter";
const clientService = new GenericService(CLIENT_ROUTE);

const PriceFormation = () => {
  const { company } = useParams();

  const { sendRequest, loading } = useFnRequest(addCollectionToList);

  const saveHandler = useCallback(
    (form: TPriceFormationForm) => {
      return sendRequest({
        collection: form.collection.ID,
        company: form.company.id,
        clientId: form.client ? form.client.ID : undefined,
      });
    },
    [sendRequest]
  );

  return (
    <FormContainer<TPriceFormationForm>
      title={"Criar lista"}
      schema={priceFormationSchema}
      saveFunction={saveHandler}
      subtitle={"Nova lista"}
      loading={loading}
      subtitleWatchField={"company.companyName"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <AutoCompleteGeneric<ISelectCompany>
              name="company"
              label="Empresa"
              getOptionLabel={(option) => option.companyName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              request={companiesList}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AutoCompleteGeneric<ISelectTypeCaptalize>
              SearchAsYouTypeParams={(v) => ({
                company: Number(company),
                searchBy: v,
              })}
              name="collection"
              label="Coleção"
              getOptionLabel={(option) => option.NAME}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              request={collectionList}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AutoCompleteGeneric<ISelectTypeCaptalize>
              SearchAsYouTypeParams={(v) => ({
                company: Number(company),
                searchBy: v,
              })}
              name="client"
              label="Cliente"
              getOptionLabel={(option) => option.NAME}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              request={clientService.genericList}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default PriceFormation;
