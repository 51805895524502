import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ILogoProps {
  paddingTop?: number;
  paddingBottom?: number;
  logoWidth: string | number;
  src: string;
  alt: string;
  link?: string;
}

const Logo = ({
  logoWidth,
  paddingBottom,
  paddingTop,
  src,
  alt,
  link,
}: ILogoProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={() => {
        if (link) {
          window.open(link);
        } else {
          navigate("");
        }
      }}
    >
      {theme.palette.mode === "light" ? (
        <img
          src={src}
          style={{ width: logoWidth, cursor: "pointer" }}
          alt={alt}
        />
      ) : (
        <img
          src={src}
          style={{ width: logoWidth, cursor: "pointer" }}
          alt={alt}
        />
      )}
    </Box>
  );
};

export default Logo;
