import { Box, Stack, Typography } from "@mui/material";

import { Button } from "../../UI/Button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form } from "../../UI/Form";
import { FormInput } from "../../UI/Form/FormTextInput";

const resetPasswordSchema = z
  .object({
    password: z.string().min(1, "A senha é obrigatória"),
    confirmPassword: z.string().min(1, "O confirmar nova senha é obrigatório"),
  })
  .superRefine((val, ctx) => {
    if (val.password !== val.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["confirmPassword"],
        message: "As senhas não coincidem",
      });
    }
  });

export type TResetPasswordForm = z.infer<typeof resetPasswordSchema>;

const ResetPasswordForm = ({
  loading,
  onSubmit,
}: {
  loading: boolean;
  onSubmit: (values: TResetPasswordForm) => Promise<void>;
}) => {
  const methods = useForm<TResetPasswordForm>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const { handleSubmit } = methods;

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 10,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack textAlign={"center"} mb={4}>
          <Typography variant="h6" mb={1}>
            Nova senha
          </Typography>
          <Typography variant="subtitle2">
            Insira sua nova senha, e em seguida confirme a nova senha para
            alterá-la
          </Typography>
        </Stack>

        <FormInput
          sx={{ mb: 2 }}
          name="password"
          label="Nova senha"
          size="small"
          type="password"
        />
        <FormInput
          sx={{ mb: 2 }}
          name="confirmPassword"
          label="Confirmar nova senha"
          size="small"
          type="password"
        />

        <Button
          loading={loading}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
        >
          Alterar senha
        </Button>
      </Box>
    </Form>
  );
};

export default ResetPasswordForm;
