import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Modal } from "components/UI/Modal";
import PivotDataGrid from "components/UI/PivotDataGrid/PivotDataGrid";
import { IColumn } from "components/UI/PivotDataGrid/models";
import { useNotificationContext } from "contexts/Notification-Context";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { IProduct } from "models/product";
import { memo, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteDyeingProduct, getDyeingProduct } from "services/dyeing.service";
import { AddProductModal } from "./AddProductModal/AddProductModal";

export const PriceList = memo(() => {
  const { clientId, collection, company } = useParams();

  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);

  const { setMessage } = useNotificationContext();

  const { sendRequest } = useFnRequest(deleteDyeingProduct);

  const [open, setOpen] = useState(false);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setSelectedProduct(null);
  }, []);

  const columns: IColumn[] = [
    {
      caption: "Cod. Engenharia",
      dataField: "codigoEngenharia",
      width: 150,
      renderCell: (e) =>
        e.data.CODIGOENGENHARIA ? e.data.CODIGOENGENHARIA : "-",
    },
    {
      caption: "Ficha Técnica",
      dataField: "descricaoFichaTecnica",
    },
    {
      caption: "Especificação",
      dataField: "descricaoEspecificacao",
    },
    {
      caption: "Markup",
      dataField: "porcentagemMarkup",
      dataType: "number",
      width: 200,
    },
    {
      caption: "Cond. Pgto",
      dataField: "porcentagemPagamento",
      width: 200,
      dataType: "number",
      renderCell: (params) => {
        return params.data.porcentagemPagamento
          ? params.data.porcentagemPagamento.toFixed(2)
          : "-";
      },
    },
    {
      caption: "Lucro",
      dataField: "porcentagemLucro",
      width: 150,
      dataType: "number",
      renderCell: (params) => {
        return params.data.porcentagemLucro
          ? params.data.porcentagemLucro.toFixed(2)
          : "-";
      },
    },
    {
      caption: "Preço",
      dataField: "valorTotal",
      width: 200,
      renderCell: (e) =>
        e.data.valorTotal ? "R$ " + e.data.valorTotal.toFixed(2) : "R$ 0.00",
    },
  ];

  const deleteHandler = useCallback(
    async (value: IProduct) => {
      const { data, success } = await sendRequest(value.idFichaTecnica);
      if (data && success) {
        setMessage({
          message: "Produto removido com sucesso",
          type: "success",
        });
        return true;
      }
      return false;
    },
    [sendRequest, setMessage]
  );

  const props = useMemo(() => {
    return {
      company: Number(company),
      collection: Number(collection),
      client: Number(clientId),
    };
  }, [company, collection, clientId]);

  const editHandler = useCallback((data: IProduct) => {
    setOpen(true);
    setSelectedProduct(data.idFichaTecnica);
  }, []);

  return (
    <>
      <Modal open={open} onClose={closeHandler} title="Adicionar Produto">
        <AddProductModal
          onClose={closeHandler}
          idFichaTecnica={selectedProduct}
        />
      </Modal>
      <PivotDataGrid<IProduct>
        title="Adicionar Produtos"
        columns={columns}
        request={getDyeingProduct}
        showBackButton
        onDelete={deleteHandler}
        onEdit={editHandler}
        showPagination={false}
        serviceProps={props}
        customKey={"idFichaTecnica"}
        actionButton={{
          label: "Produto",
          startIcon: <AddRoundedIcon />,
          onClick: openHandler,
        }}
      />
    </>
  );
});
