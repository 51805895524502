import { Box, Grid } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { IQualityFormProps } from "./models";
import { TabPanel } from "components/UI/Tabs/TabPanel/TabPanel";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/UI/Form";
import { Button } from "components/UI/Button";
import { useRequest } from "hooks/useRequest/useRequets";
import {
  createOrUpdateQuality,
  getQualityById,
} from "services/quality.service";
import { TQualityForm, qualitySchema } from "./qualitySchema";
import { QualityFields } from "./QualityFields/QualityFields";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import { useNotificationContext } from "contexts/Notification-Context";
import { useQueryClient } from "react-query";

export const QualityForm = memo(
  ({ value, id, squa, onClose }: IQualityFormProps) => {
    const { setMessage } = useNotificationContext();

    const methods = useForm<TQualityForm>({
      resolver: zodResolver(qualitySchema),
    });

    const queryClient = useQueryClient();

    const { sendRequest, loading } = useFnRequest(createOrUpdateQuality);

    const { data } = useRequest({
      queryKey: ["get-quality", id, squa],
      requestBody: { id: id ?? 0, squa: squa ?? 0 },
      request: getQualityById,
      permission: !!id && !!squa,
    });

    const { handleSubmit, reset } = methods;

    const onSubmit = useCallback(
      async (values: TQualityForm) => {
        const { data, success } = await sendRequest({
          ...values,
          clientId: Number(values.clientId),
          code: Number(values.code),
          date: values.date.toISOString().substring(0, 10),
          sequence: Number(values.sequence),
        });
        if (data && success) {
          onClose();
          setMessage({
            message: "Registro salvo com sucesso",
            type: "success",
          });
          queryClient.invalidateQueries({ queryKey: ["datagrid-request"] });
        }
      },
      [sendRequest, onClose, setMessage, queryClient]
    );

    useEffect(() => {
      if (id && data) {
        const quality = data[0];

        reset({
          date: new Date(quality.D_ADD),
          resolv: quality.RESOL === 1,
          clientName: quality.NM_CLI,
          sequence: quality.S_QUA.toString(),
          clientId: id.toString(),
          code: quality.CD_CLI,
          article: quality.DS_ART,
          description: quality.DS_DEF,
          responsible: quality.NM_RESP,
        });
      }
    }, [id, data, reset]);

    return (
      <TabPanel value={value ?? 0} index={0}>
        <Form {...methods}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2}>
              <QualityFields id={id} />
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: 1,
                }}
              >
                <Button variant="outlined" disabled={loading} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </TabPanel>
    );
  }
);
