import { Stack, Typography } from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";

interface INoData {
  message?: string | null;
}

const NoData: React.FC<INoData> = ({ message }) => {
  return (
    <Stack alignItems={"center"} spacing={2}>
      <FolderOffIcon fontSize="large" />
      <Typography variant="subtitle1">
        {message ?? "Nenhuma informação foi encontrada"}
      </Typography>
    </Stack>
  );
};

export default NoData;
