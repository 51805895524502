import { Box } from "@mui/material";

export const Environment = () => {
  let backgroundColor = "#26e077";
  let tagName = process.env.REACT_APP_ENVIRONMENT;

  if (tagName === "homolog") {
    backgroundColor = "#2A7ABE";
  }

  return (
    <Box
      position={"fixed"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      zIndex={1000}
      sx={{
        width: "100vw",
        height: ".92rem",
        backgroundColor: backgroundColor,
        color: backgroundColor === "#26e077" ? "#000" : "#fff",
        fontSize: "0.8rem",
      }}
    >
      {tagName}
    </Box>
  );
};
