import { Grid } from "@mui/material";
import AutoCompleteGeneric from "components/UI/Form/AutocompleteGeneric/AutoCompleteGeneric";
import { IProfit } from "models/price";
import { memo } from "react";
import { profitList } from "services/price.service";

export const ProfitField = memo(() => {
  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteGeneric<IProfit>
        name="cost.profit"
        label="Lucro"
        getOptionLabel={(option) => option.NAME}
        isOptionEqualToValue={(option, value) => option.ID === value.ID}
        size="small"
        request={profitList}
        fullWidth
      />
    </Grid>
  );
});
