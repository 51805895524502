import { Box } from "@mui/material";
import { TabsComponent } from "components/UI/Tabs/TabsComponent/TabsComponent";
import { ITab } from "components/UI/Tabs/TabsComponent/models";
import { SyntheticEvent, memo, useState } from "react";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import { QualityForm } from "./QualityForm/QualityForm";
import { IEditQualityModalProps } from "./models";
import { Actions } from "./Actions/Actions";

export const EditQualityModal = memo(
  ({ data, onClose }: IEditQualityModalProps) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const tabs: ITab[] = [
      {
        label: "Qualidade",
        tabIndex: 0,
        icon: <EqualizerRoundedIcon fontSize="small" />,
      },
      {
        label: "Ações",
        tabIndex: 1,
        icon: <ChatBubbleRoundedIcon fontSize="small" />,
      },
    ];

    return (
      <Box>
        <TabsComponent value={value} handleChange={handleChange} tabs={tabs} />
        <QualityForm
          value={value}
          id={data.clientId}
          squa={data.sequence}
          onClose={onClose}
        />
        <Actions value={value} id={data.clientId} squa={data.sequence} />
      </Box>
    );
  }
);
