import QualityPage from "pages/Pcp/Quality/QualityPage";
import { RouteObject } from "react-router-dom";

const PcpRoutes: RouteObject[] = [
  {
    path: "qualidade",
    element: <QualityPage />,
  },
];

export default PcpRoutes;
