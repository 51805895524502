import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Button } from "components/UI/Button";
import { memo, useCallback, useMemo, useState } from "react";
import { IFilterProps } from "./models";
import { useSearchParams } from "react-router-dom";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import { FiltersList } from "./FiltersList/FiltersList";
import { Modal } from "components/UI/Modal";

export const Filters = memo(({ filters, onFilterHandler }: IFilterProps) => {
  const [params] = useSearchParams();

  const [open, setOpen] = useState(false);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const openModalHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const currentFilters = useMemo(
    () => Object.fromEntries(params.entries()),
    [params]
  );

  const filterHandler = useCallback(() => {
    onFilterHandler(currentFilters);
    closeModalHandler();
  }, [currentFilters, onFilterHandler, closeModalHandler]);

  if (!filters) return null;

  if (matches) {
    return (
      <>
        <Modal open={open} onClose={closeModalHandler} title="Filtros">
          <Stack sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FiltersList filters={filters} />
            <Button
              variant="contained"
              startIcon={<SearchRoundedIcon />}
              onClick={filterHandler}
            >
              Pesquisar
            </Button>
          </Stack>
        </Modal>
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <IconButton onClick={openModalHandler}>
            <FilterAltRoundedIcon />
          </IconButton>
        </Stack>
      </>
    );
  }

  return (
    <Stack
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <FiltersList filters={filters} />
      <Button
        variant="contained"
        startIcon={<SearchRoundedIcon />}
        onClick={filterHandler}
      >
        Pesquisar
      </Button>
    </Stack>
  );
});
