import { z } from "zod";

const actionTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

export const actionSchema = z.object({
  date: z.date(),
  type: actionTypeSchema,
  responsible: z.string(),
  description: z.string(),
});

export type TActionForm = z.infer<typeof actionSchema>;
