import axios, { InternalAxiosRequestConfig } from "axios";
import { tokenService } from "../services/token.service";
import { removePermissions, removeUserName } from "../services/user.service";
import { authService } from "../services/auth.service";
import {
  clearEstablishmentCode,
  getEIFromUrl,
  getEstToken,
} from "../services/establishment.service";
import { getDomain } from "services/domain.service";

export const GlobalAxios = axios.create({
  baseURL: "https://" + getDomain() + process.env.REACT_APP_API,
  // baseURL: "http://192.168.70.248:4000",
  headers: {
    "Access-Control-Allow-Origin": "*",
    client_id: process.env.CLIENT_ID,
  },
});

GlobalAxios.interceptors.request.use(function (config) {
  let token = getEstToken();

  if (token) {
    config.headers.Authorization = "Bearer " + token.token;
  }

  return config;
});

export const InterceptorRefresh = async (
  config: InternalAxiosRequestConfig<any>
) => {
  if (!tokenService.hasRefreshTokens()) {
    return config;
  }

  const establishmentcode = getEIFromUrl();

  let token = tokenService
    .getAccessTokens()
    .find((t) => t.code === establishmentcode);

  const tokens = await refreshToken(token?.establishmentId);

  if (tokens && establishmentcode !== undefined) {
    tokenService.saveTokens(
      tokens.token,
      tokens.refreshToken,
      tokens.establishment_tenant_sequential,
      token?.establishmentId
    );
    config.headers.Authorization = "Bearer " + tokens.token;
  }

  return config;
};

export const removeUserData = () => {
  tokenService.removeAllTokens();
  removePermissions();
  clearEstablishmentCode();
  removeUserName();
};

const refreshToken = async (establishmentId?: string) => {
  const response = await authService.refresh_token(establishmentId);
  return response.data;
};
