import { Box } from "@mui/material";
import { Button } from "components/UI/Button";
import { memo } from "react";
import { IAddProductActionsProps } from "./models";

export const AddProductActions = memo(
  ({
    disabled,
    typeSubmit = false,
    loading,
    onCancel,
    onNext,
  }: IAddProductActionsProps) => {
    return (
      <Box
        sx={{
          mt: 2,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: 1,
        }}
      >
        <Button variant="outlined" disabled={loading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={disabled || loading}
          loading={loading}
          type={typeSubmit ? "submit" : "button"}
          onClick={onNext}
        >
          {typeSubmit ? "Salvar" : "Próximo"}
        </Button>
      </Box>
    );
  }
);
