import { GlobalAxios } from "../axios/axios-config";
import { ISelectType, ISelectTypeCaptalize } from "../models/requests";

export class GenericService {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  list = (params: string) => {
    return GlobalAxios.get<ISelectType[]>(this.route_path + `/${params}`);
  };

  genericList = (params: any) => {
    return GlobalAxios.get<ISelectTypeCaptalize[]>(this.route_path, {
      params: params,
    });
  };
}

export default GenericService;
