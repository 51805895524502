import { Divider, Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { MarkupField } from "./MarkupField/MarkupField";
import { PaymentField } from "./PaymentField/PaymentField";
import { ProfitField } from "./ProfitField/ProfitField";
import { useProduct } from "contexts/Product-Context/product-context";
import { AddProductActions } from "../AddProductActions/AddProductActions";
import { ICostsFieldsProps } from "./models";
import { useFormContext } from "react-hook-form";
import { TAddProductForm } from "../addProductSchema";
import { FormData } from "components/UI/Form/FormData/FormData";

export const CostFields = memo(
  ({ idFichaTecnica, onClose }: ICostsFieldsProps) => {
    const { step, onChangeStep } = useProduct();

    const { watch } = useFormContext<TAddProductForm>();

    const selectedMarkup = watch("cost.markup");

    const selectedPayment = watch("cost.payment");

    const selectedProfit = watch("cost.profit");

    const inputsFilled = selectedMarkup && selectedPayment && selectedProfit;

    const nextStepHandler = useCallback(() => {
      onChangeStep(2);
    }, [onChangeStep]);

    if (step < 1 && !idFichaTecnica) return null;

    if (step > 1 && !idFichaTecnica)
      return (
        <>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormData
                label="Markup"
                data={selectedMarkup ? selectedMarkup.CD_DS : "-"}
                chipValue={
                  selectedMarkup
                    ? `${Number(selectedMarkup.MKP_TOT).toFixed(2)}%`
                    : "-"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormData
                label="Condição de Pagamento"
                data={selectedPayment ? selectedPayment.CD_DS : "-"}
                chipValue={
                  selectedPayment
                    ? `${Number(selectedPayment.JUR_TOT).toFixed(2)}%`
                    : "-"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormData
                label="Lucro"
                data={selectedProfit ? selectedProfit.NAME : "-"}
                chipValue={
                  selectedProfit
                    ? `${Number(selectedProfit.INDICE).toFixed(2)}%`
                    : "-"
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
        </>
      );

    return (
      <Grid container spacing={2} my={idFichaTecnica ? 2 : 0}>
        <MarkupField />
        <PaymentField />
        <ProfitField />
        {!idFichaTecnica && (
          <AddProductActions
            disabled={!inputsFilled}
            onCancel={onClose}
            onNext={nextStepHandler}
          />
        )}
      </Grid>
    );
  }
);
