import React from "react";

import { Button as Btn, CircularProgress, Stack } from "@mui/material";
import { TButtonProps } from "./models";

export const Button: React.FC<TButtonProps> = ({
  loading = false,
  children,
  ...otherProps
}) => {
  return (
    <Btn
      {...otherProps}
      disabled={loading ? true : otherProps.disabled || false}
    >
      {loading && (
        <Stack alignItems={"center"}>
          <CircularProgress size={"1.5em"} />
        </Stack>
      )}
      {!loading && children}
    </Btn>
  );
};
