import { Box, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { motion } from "framer-motion";
import CustomListItemButton from "./ListItemButton";
import { IMenu } from "../../../services/menus.service";
import DynamicIcon from "../DynamicIcon";

const SideMenuItem = ({
  item,
  menus,
  onClose,
}: {
  item: IMenu;
  menus: IMenu[];
  onClose?: () => void;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const toggleSubmenu = () =>
    setOpenSubMenu((prevState) => (prevState = !prevState));

  const openHandler = (path: string) => {
    if (onClose) {
      onClose();
    }
    if (path === "/") {
      navigate("");
    } else {
      navigate(path);
    }

    // A for loop to remove any filter from a page when user goes to other page
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key && key.includes(pathname)) {
        localStorage.removeItem(key);
        i--;
      }
    }
  };

  return (
    <>
      {!item.parentId && (
        <CustomListItemButton
          active={item.link === "/" && pathname.length === 4}
          onClick={() =>
            !item.link ? toggleSubmenu() : openHandler(item.link)
          }
        >
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
              <DynamicIcon icon={item.iconClass} />
            </Box>

            <Box component="span" sx={{ textTransform: "initial" }}>
              {item.description}
            </Box>
          </Box>

          {!item.link && (
            <Box
              width={"10%"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <motion.div
                animate={{ rotate: openSubMenu ? 0 : 180 }}
                transition={{ type: "tween", duration: 0.2, ease: "easeOut" }}
                style={{ height: "24px" }}
              >
                <KeyboardArrowDownRoundedIcon />
              </motion.div>
            </Box>
          )}
        </CustomListItemButton>
      )}

      {!item.parentId && openSubMenu && (
        <Box
          sx={(theme) => ({
            pl: 4,
          })}
        >
          {menus
            .filter((menu) => menu.parentId === item.id)
            .map((submenu) => (
              <CustomListItemButton
                height={40}
                onClick={() => openHandler(submenu.link || "")}
                active={!!pathname.substring(1).includes(submenu.link)}
                key={submenu.id}
              >
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Box component="span" sx={{ width: 20, height: 30, mr: 2 }}>
                    <DynamicIcon icon={submenu.iconClass} />
                  </Box>

                  <Box
                    component="span"
                    sx={{
                      height: 40,
                      lineHeight: 1.2,
                      textTransform: "initial",
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    {submenu.description}
                  </Box>
                </Stack>
              </CustomListItemButton>
            ))}
        </Box>
      )}
    </>
  );
};

export default SideMenuItem;
