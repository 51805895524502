import { Grid } from "@mui/material";
import { AutoCompleteColor } from "components/UI/Form/AutocompleteColor";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { colorsList } from "services/dyeing.service";

export const SelectColor = memo(() => {
  const { company } = useParams();

  return (
    <Grid item xs={12} sm={6}>
      <AutoCompleteColor
        SearchAsYouTypeParams={(v) => ({
          searchBy: v,
          company: Number(company),
        })}
        name="product.cor"
        label="Cor"
        getOptionLabel={(option) => option.name}
        request={colorsList}
        size="small"
        fullWidth
      />
    </Grid>
  );
});
