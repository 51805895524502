import React, { createContext, useContext } from "react";
import { IUserContextProps, IUserProviderProps } from "./models";

export const UserContext = createContext<IUserContextProps>({
  user: {
    id: "",
    name: "",
    mail: "",
    establishmentId: null,
    userEstablishments: [],
    establishmentSlogan: null,
    enable: true,
    userRoles: [],
    privileges: [],
    mediaFiles: [],
  },
});

export const UserProvider: React.FC<IUserProviderProps> = ({
  children,
  user,
}) => {
  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  return context;
};
