import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "../../UI/Button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form } from "../../UI/Form";
import { FormInput } from "../../UI/Form/FormTextInput";
import { Link } from "react-router-dom";

const loginFormSchema = z.object({
  login: z.string().min(1, "O login é obrigatório"),
  password: z.string().min(4, "A senha precisa de no mínimo 4 caracteres"),
});

export type TLoginFormData = z.infer<typeof loginFormSchema>;

const LoginForm = ({
  loading,
  onSubmit,
}: {
  loading: boolean;
  onSubmit: (values: TLoginFormData) => Promise<void>;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm<TLoginFormData>({
    resolver: zodResolver(loginFormSchema),
  });

  const { handleSubmit } = methods;

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 10,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <FormInput name="login" label="Login" size="small" />

          <FormInput
            name="password"
            label="Senha"
            size="small"
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {!showPassword && <RemoveRedEyeIcon />}
                    {showPassword && <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ width: "100%", my: 2 }}
        >
          <Link style={{ textDecoration: "none" }} to="/esqueci-senha">
            <Typography
              color={"primary"}
              sx={{ "&:hover": { textDecoration: "underline" } }}
            >
              Esqueceu a senha?
            </Typography>
          </Link>
        </Stack>

        <Button
          loading={loading}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
        >
          Acessar
        </Button>
      </Box>
    </Form>
  );
};

export default LoginForm;
