export enum EBusinessSector {
  CONFECCAO = 12,
  TINTURARIA = 3,
  TECELAGEM_TINTURARIA = 14,
}

export interface IProfitabilityParams {
  company: string;
  startDate: string;
  endDate: string;
  businessSector: EBusinessSector;
}
