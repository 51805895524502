import { Grid } from "@mui/material";
import { AutoCompleteUncontrolled } from "components/AutoCompleteUncontrolled";
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { IAutoCompleteFilterProps } from "./models";
import { ISelectType } from "contexts/Config-Context";
import { useSearchParams } from "react-router-dom";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";

export const AutoCompleteFilter = memo(
  ({ filter }: IAutoCompleteFilterProps) => {
    const {
      key,
      return: returnValue = "id",
      isClearable = true,
      label,
      extraSmallSize,
      smallSize,
      options,
      onDefaultValue,
      request,
    } = filter;

    const [filterOptions, setFilterOptions] = useState<ISelectType[]>(
      options ?? []
    );

    const defaultValue = useMemo(
      () => (onDefaultValue ? onDefaultValue(filterOptions) : null),
      [onDefaultValue, filterOptions]
    );

    const [params, setParams] = useSearchParams();

    const onChangeParams = useCallback(
      (value: string) => {
        if (value.length === 0) {
          setParams((p) => {
            p.delete(key);

            return p;
          });
          return;
        }

        setParams((p) => {
          p.set(key, value);

          return p;
        });
      },
      [key, setParams]
    );

    const { sendRequest } = useFnRequest(request!);

    const fetchOptions = useCallback(async () => {
      const { data, success } = await sendRequest(null);
      if (data && success) {
        setFilterOptions(data);
      }
    }, [sendRequest]);

    const onChangeHandler = useCallback(
      (value: ISelectType | null) => {
        if (value) {
          if (returnValue === "name") {
            onChangeParams(value.name);
            return;
          }
          if (returnValue === "code") {
            onChangeParams(value.code ?? "");
            return;
          }
          onChangeParams(value.id);
          return;
        } else {
          onChangeParams("");
        }
      },
      [returnValue, onChangeParams]
    );

    const currentValue = useMemo(
      () =>
        filterOptions.find((item) => {
          if (returnValue === "name") {
            return item.name === params.get(key);
          }
          if (returnValue === "code") {
            return item.code! === params.get(key);
          }
          return item.id === params.get(key);
        }),
      [filterOptions, key, params, returnValue]
    );

    useEffect(() => {
      if (options) {
        setFilterOptions(options);
        return;
      }
      if (request) {
        fetchOptions();
      }
    }, [options, request, fetchOptions]);

    useLayoutEffect(() => {
      if (!currentValue && defaultValue) {
        onChangeHandler(defaultValue);
      }
    }, [currentValue, defaultValue, onChangeHandler]);

    return (
      <Grid item xs={extraSmallSize} sm={smallSize}>
        <AutoCompleteUncontrolled
          value={currentValue ?? null}
          isClearable={isClearable}
          onValueSelected={onChangeHandler}
          label={label}
          options={filterOptions}
          service={request}
          size="small"
        />
      </Grid>
    );
  }
);
