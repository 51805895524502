import 'devextreme/dist/css/dx.light.css';
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "./router/routes";
import { ThemeContextProvider } from "./contexts/Theme-Context";
import themeOptions from "./theme";
import { CssBaseline } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { AuthContextProvider } from "./contexts/Auth-Context";
import { memo, useCallback, useEffect } from "react";
import {
  GlobalAxios,
  InterceptorRefresh,
  removeUserData,
} from "./axios/axios-config";
import { EstablishmentContextProvider } from "./contexts/Establishment-Context/establishment-context";
import { removePermissions } from "./services/user.service";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "react-query";
import { MediasContextProvider } from "./contexts/Medias-Context/medias-context";

const router = createBrowserRouter(routes);

function App() {
  const queryClient = new QueryClient();

  const logoutUser = useCallback(() => {
    removePermissions();
    removeUserData();
    router.navigate("/login");
  }, []);

  const getGAKey = useCallback(async () => {
    const TRACKING_ID = "G-GRHSCBXCGF";
    ReactGA.initialize(TRACKING_ID);
  }, []);

  useEffect(() => {
    getGAKey();
  }, [getGAKey]);

  return (
    <AuthContextProvider
      axios={GlobalAxios}
      refreshToken={InterceptorRefresh}
      onLogoutUser={logoutUser}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeContextProvider themeOptions={themeOptions}>
          <EstablishmentContextProvider>
            <MediasContextProvider>
              <ConfirmProvider>
                <CssBaseline />
                <RouterProvider router={router} />
              </ConfirmProvider>
            </MediasContextProvider>
          </EstablishmentContextProvider>
        </ThemeContextProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default memo(App);
