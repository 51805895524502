import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { IAutoCompleteUncontrolledProps } from "./models";
import { memo, useCallback, useEffect, useState } from "react";
import { ISelectType } from "models/requests";
import { useDebounce } from "hooks/useDebounce";
import { useFnRequest } from "hooks/useFnRequest/useFnRequest";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const AutoCompleteUncontrolled = memo(
  ({
    value,
    options,
    isClearable = true,
    onValueSelected,
    service,
    ...otherProps
  }: IAutoCompleteUncontrolledProps) => {
    const [servOptions, setServOptions] = useState<ISelectType[]>([]);

    const [searchText, setSearchText] = useState("");

    const searchQuery = useDebounce(searchText, 500);

    const { sendRequest, loading } = useFnRequest(service!);

    const fetchOptions = useCallback(async () => {
      const { data, success } = await sendRequest(undefined);
      if (data && success) {
        setServOptions(data);
      }
    }, [sendRequest]);

    useEffect(() => {
      if (service) {
        fetchOptions();
      }
    }, [fetchOptions, service]);

    return (
      <Autocomplete
        loading={loading}
        loadingText={"Carregando"}
        noOptionsText={"Sem opções"}
        value={value}
        onChange={(e, value) => {
          onValueSelected(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        clearIcon={isClearable ? <CloseRoundedIcon /> : null}
        options={
          options
            ? options.filter((option) =>
                option.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
            : servOptions.filter((option) =>
                option.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
        }
        fullWidth
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            {...otherProps}
            onChange={(e) => setSearchText(e.target.value)}
            fullWidth
          />
        )}
      />
    );
  }
);
