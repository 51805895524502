import { useQualityActions } from "contexts/Quality-Actions-Context/quality-actions-context";
import { memo, useCallback } from "react";
import DataGrid, { Column, DataGridTypes } from "devextreme-react/data-grid";
import { Box } from "@mui/material";
import { Button } from "components/UI/Button";
import { IActionsListProps } from "./models";
import { RowClickEvent } from "devextreme/ui/data_grid";
import { IGetQualityAction } from "models/quality";
import { useNotificationContext } from "contexts/Notification-Context";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import "./styles.css";
import { ActionDeleteButton } from "../ActionDelete/ActionDeleteButton";

export const ActionsList = memo(
  ({ onGetActionHandler, onChangeRender }: IActionsListProps) => {
    const { actions } = useQualityActions();

    const { setMessage } = useNotificationContext();

    const newActionHandler = useCallback(() => {
      onGetActionHandler(null);
      onChangeRender("form");
    }, [onChangeRender, onGetActionHandler]);

    const onRowClick = useCallback(
      (e: RowClickEvent<IGetQualityAction, any>) => {
        if (e.data.TP_ACA === 1 || e.data.TP_ACA === 99) {
          setMessage({
            message: "Não é possível editar ações de abertura e fechamento",
            type: "info",
          });
          return;
        }
        const currentDate = new Date(e.data.date);
        currentDate.setHours(currentDate.getHours() + 3);
        onGetActionHandler({
          date: currentDate,
          description: e.data.description,
          responsible: e.data.responsible,
          s_aca: e.data.S_ACA,
        });
        onChangeRender("form");
      },
      [onChangeRender, onGetActionHandler, setMessage]
    );

    const cellPreparedHandler = (
      e: DataGridTypes.CellPreparedEvent<IGetQualityAction, any>
    ) => {
      const elem = e.cellElement;
      elem.style.cursor = "pointer";
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          gap: 2,
        }}
      >
        <DataGrid
          dataSource={actions}
          width={"100%"}
          height={"calc(100vh - 560px)"}
          showBorders={false}
          onCellPrepared={cellPreparedHandler}
          onRowClick={onRowClick}
          noDataText="Nenhum registro encontrado"
        >
          <Column
            dataField={"date"}
            caption={"Data"}
            format={"dd/MM/yyyy"}
            alignment={"left"}
            cellRender={(e) => {
              const date = new Date(e.data.date.substring(0, 10));
              if (date.getHours() === 21) {
                date.setHours(date.getHours() + 3);
              }
              return date.toLocaleDateString("pt-BR");
            }}
          />
          <Column
            dataField={"description"}
            caption={"Ação"}
            alignment={"left"}
          />
          <Column
            dataField={"responsible"}
            caption={"Responsável"}
            alignment={"left"}
          />
          <Column
            dataField={"delete"}
            caption={""}
            alignment={"left"}
            width={50}
            cellRender={(e) => {
              console.log(e.data);
              return (
                <ActionDeleteButton
                  company={e.data.clientId}
                  sequenceAction={e.data.S_ACA}
                  sequenceQuality={e.data.sequence}
                />
              );
            }}
          />
        </DataGrid>
        <Button
          variant="contained"
          startIcon={<AddRoundedIcon />}
          onClick={newActionHandler}
        >
          Nova ação
        </Button>
      </Box>
    );
  }
);
