import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { IColumn } from "components/UI/PivotDataGrid/models";
import PivotDataGrid from "components/UI/PivotDataGrid/PivotDataGrid";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { useRequest } from "hooks/useRequest/useRequets";
import { IPriceFormation } from "models/price-formation";
import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { companiesList } from "services/companies.service";
import { getDyeingList } from "services/dyeing.service";

const PriceFormationPage = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const companyId = useMemo(() => params.get("company"), [params]);

  const columns: IColumn[] = [
    {
      caption: "Coleção",
      dataField: "collectionName",
    },
    {
      caption: "Cliente",
      dataField: "clientName",
    },
    {
      caption: "Cod. Cliente",
      dataField: "clientCode",
      width: 200,
    },
  ];

  const { data } = useRequest({
    queryKey: ["companies-list"],
    requestBody: null,
    request: companiesList,
  });

  const rowClickHandler = useCallback(
    (e: DataGridTypes.RowClickEvent<IPriceFormation>) => {
      const data = e.data;
      navigate(
        `${companyId}/${data.collectionID}/${data.clientID}/${data.clientCode}/${data.tabelaCustoInsumo}/${data.tabelaCustoOperacao}`
      );
    },
    [navigate, companyId]
  );

  const addListHandler = useCallback(() => {
    navigate(`${companyId}/new`);
  }, [navigate, companyId]);

  const options = useMemo(() => data, [data]);

  return (
    <PivotDataGrid<IPriceFormation>
      title="Formação de Preço"
      columns={columns}
      request={getDyeingList}
      hideDelete
      onRowClick={rowClickHandler}
      actionButton={{
        label: "Lista",
        startIcon: <AddRoundedIcon />,
        onClick: addListHandler,
      }}
      confirmOptions={{
        title: "Tem certeza que deseja excluir o Preço?",
        description: "Essa ação irá remover permanentemente o Preço.",
      }}
      filters={[
        {
          key: "company",
          extraSmallSize: 12,
          smallSize: 4,
          label: "Empresa",
          type: "autocomplete",
          isClearable: false,
          onDefaultValue: (options) => options[0],
          options: options
            ? options.map((d) => ({ id: d.id.toString(), name: d.companyName }))
            : undefined,
        },
      ]}
    />
  );
};

export default PriceFormationPage;
