import { z } from "zod";

const materialSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string().optional().nullable(),
  cost: z.number(),
  idFichaTecnica: z.number(),
  unidadeMedida: z.number(),
  tipoFichaTecnica: z.number(),
});

const roadmapSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const compositionSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.number().optional().nullable(),
});

const colorSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const productSchema = z.object({
  roteiro: roadmapSchema,
  cor: colorSchema,
  composicao: compositionSchema,
  material: materialSchema,
});

const markupSchema = z.object({
  ID_TAB: z.number(),
  CD_DS: z.string(),
  MKP_TOT: z.number(),
  MC_TOT: z.number(),
});

const paymentSchema = z.object({
  ID_PGTO: z.number(),
  CD_DS: z.string(),
  JUR_TOT: z.number(),
});

const profitSchema = z.object({
  ID: z.number(),
  NAME: z.string(),
  INDICE: z.number(),
});

const costSchema = z.object({
  markup: markupSchema,
  payment: paymentSchema,
  profit: profitSchema,
  precoSugerido: z.string(),
  ir: z.number(),
  csll: z.number(),
  grossProfit: z.number(),
});

export const addProductSchema = z.object({
  product: productSchema,
  cost: costSchema,
});

export type TAddProductForm = z.infer<typeof addProductSchema>;
