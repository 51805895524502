import { GlobalAxios } from "../axios/axios-config";

const MENUS_ROUTE = "/api/menu";

export interface IMenu {
  id: string;
  parentId: string;
  domainId: string | null;
  name: string;
  normalizedName: string;
  description: string;
  normalizedDescription: string;
  iconClass: string;
  link: string;
  target: string;
  order: number;
  enable: boolean;
}

export class MenusService {
  list = () => {
    return GlobalAxios.get<IMenu[]>(MENUS_ROUTE);
  };
}

export default MenusService;
