import {
  IGetProduct,
  INewCollectionInList,
  INewProduct,
  IProduct,
  IProductParams,
  ISelectProduct,
} from "models/product";
import { GlobalAxios } from "./../axios/axios-config";
import { IPriceFormation, IPriceFormationParams } from "models/price-formation";
import { IMaterialListParams } from "models/material";
import { IColorsParams, ISelectColor } from "models/colors";
import { ISelectType } from "contexts/Config-Context";
import { ISelectCompositionParams } from "models/composition";
import { ISelectRoadmapParams } from "models/roadmap";

const DYEING_ROUTE = "api/dyeing";

export const getDyeingList = (params: IPriceFormationParams) => {
  return GlobalAxios.get<IPriceFormation[]>(DYEING_ROUTE + "/list", {
    params,
  });
};

export const getDyeingProduct = (params: IProductParams) => {
  return GlobalAxios.get<IProduct[]>(DYEING_ROUTE + "/product", {
    params,
  });
};

export const deleteDyeingProduct = (id: number) => {
  return GlobalAxios.delete(DYEING_ROUTE + "/product/" + id);
};

export const materialList = (params: IMaterialListParams) => {
  return GlobalAxios.get<ISelectProduct[]>(DYEING_ROUTE + "/material", {
    params,
  });
};

export const colorsList = (params: IColorsParams) => {
  return GlobalAxios.get<ISelectColor[]>(DYEING_ROUTE + "/colors", {
    params,
  });
};

export const compositionList = (params: ISelectCompositionParams) => {
  return GlobalAxios.get<ISelectType[]>(DYEING_ROUTE + "/composition", {
    params,
  });
};

export const roadmapList = (params: ISelectRoadmapParams) => {
  return GlobalAxios.get<ISelectType[]>(DYEING_ROUTE + "/roadmap", {
    params,
  });
};

export const addProduct = (params: INewProduct) => {
  return GlobalAxios.post(DYEING_ROUTE + "/product", params);
};

export const updateProduct = ({
  idFichaTecnica,
  params,
}: {
  idFichaTecnica: number;
  params: INewProduct;
}) => {
  return GlobalAxios.put(DYEING_ROUTE + "/product/" + idFichaTecnica, params);
};

export const addCollectionToList = (params: INewCollectionInList) => {
  return GlobalAxios.post(DYEING_ROUTE + "/list", params);
};

export const getProduct = (idFichaTecnica: number) => {
  return GlobalAxios.get<IGetProduct>(
    DYEING_ROUTE + "/product/" + idFichaTecnica
  );
};
