import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const useDate = () => {
  const { i18n } = useTranslation();

  const locale = i18n.language;

  dayjs.locale(locale);

  const validateDate = (date?: dayjs.ConfigType, format?: string) => {
    if (date && dayjs(date).isValid()) {
      const dateLocale = dayjs(date).locale(locale);
      return dateLocale.format(format);
    }
    return "--";
  };

  return {
    validateDate,
    dayjs,
  };
};

export default useDate;
