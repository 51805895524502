import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { motion } from "framer-motion";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { IThemeSwitchProps } from "./models";
import { UseThemeContext } from "../../../contexts/Theme-Context";

/**
 * A theme swith to be used inside configContext
 *
 * @param param I Theme props
 * @returns and react component
 */
export const ThemeSwitch: React.FC<IThemeSwitchProps> = ({
  iconButtonProps,
}) => {
  const darkMode = localStorage.getItem("theme") === "2";
  const { setMode } = UseThemeContext();

  const onClickHandler = useCallback(() => {
    if (darkMode) {
      localStorage.setItem("theme", "1");
      setMode("1");
    } else {
      localStorage.setItem("theme", "2");
      setMode("2");
    }
  }, [darkMode, setMode]);

  return (
    <Tooltip title={"Alterar tema"} placement="right">
      <IconButton onClick={onClickHandler} {...iconButtonProps}>
        <motion.div
          animate={{ rotate: darkMode ? 360 : 0 }}
          transition={{ type: "tween", duration: 1, ease: "easeOut" }}
          style={{ height: "24px" }}
        >
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </motion.div>
      </IconButton>
    </Tooltip>
  );
};
