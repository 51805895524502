import { z } from "zod";

const companySchema = z.object(
  {
    id: z.number(),
    code: z.number(),
    companyName: z.string(),
    businessSector: z.number().nullish(),
    default: z.boolean(),
    enable: z.boolean(),
  },
  { invalid_type_error: "A empresa é obrigatória" }
);

const collectionSchema = z.object(
  {
    ID: z.number(),
    NAME: z.string(),
    CODE: z.number(),
  },
  { invalid_type_error: "A coleção é obrigatória" }
);

const clientSchema = z.object({
  ID: z.number(),
  NAME: z.string(),
  CODE: z.string(),
});

export const priceFormationSchema = z.object({
  company: companySchema,
  collection: collectionSchema,
  client: clientSchema.nullable(),
});

export type TPriceFormationForm = z.infer<typeof priceFormationSchema>;
